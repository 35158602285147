import { Injectable} from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import ObjectID from "bson-objectid"
declare var AWS: any;
declare var silabaJS: any;
declare var $: any;
declare var BarraProgreso:any;
declare var Swal: any;
declare var ___RUTAS_DISPONIBLES___: any;
declare var isNavidad: any;
declare var EstoyEnLetrapps: string;
declare var firebase: any;
declare var loopify: any;
declare var Howl: any;
declare var QRCode: any;
var GLOBAL:any;

import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Location } from '@angular/common'; 
 
import { environment } from '../../../environments/environment';

import { ApiService } from './api.service';
// import {Howl, Howler} from 'howler';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  ID_USUARIO:any = "";
  ID_INSTITUCION:any = "";
  PROMOCION_ACTUAL:any = "";
  silenciado_landing:any = false;
  isIframe:any=window.location !== window.parent.location;
  alerta = Swal;
  isModoNavidad: any = false;
  QRCode = QRCode;
  EnJuego:any = false;
  ConfiguracionTemporalJuego:any={};
  CursosLeoPrimero:any = [];
  TomoLeoPrimero:any = [];
  ClaseLeoPrimero:any = [];
  LecturasLeoPrimero:any = [];
  CursosFiltro1:any;
  CursosFiltro2:any;
  CursosFiltro3:any;
  TipoIngreso:any="";
  TipoFuentePDF:any="";
  CursosFiltro4:any;
  MuestraBarraCategorias:any=true;
  LeoPrimero:any = [];
  EstoyEnLetraps:any = false;
  EstoyEnTabulatest:any = false;
  EstoyEnJuegoEduca:any = false;
  OpcionesIntegracionLetrapps:any = {};
  OpcionesIntegracionTabulatest:any = {};
  lastPadding:any = {};
  lastFuenteInstrucciones:any = "";
  OpcionesIntegracionJuegoEduca:any = {};
  OpcionesPlataformaActual:any = {};
  CookieCursoLeoPrimero:any="";
  CookieTomoLeoPrimero:any="";
  CookieClaseLeoPrimero:any="";
  CookieLecturaLeoPrimero:any="";
  CookieTipoTextoTrivia:any="";
  PlataformaRutaActual = "juegoeduca";
  CookieCategoriaDocente:any = "";
  CookieMenuActual:any="";
  CookieMenuActualLetrapps:any="";
  lastFuente:any="Verdana";
  lastTamanioPergamino:any=20;
  lastTamanioTitulo:any=20;
  lastTamanioTablero:any=24;
  lastMayusMinus:any="toString";
  seleccionDisenioPopit:any="cuadrado";
  lastPaddingPopit:any={top:0};
  FuncionLimpiarJuego:any=false;
  BD_CATEGORIAS_METODO:any;
  //musicaAmbiente:any = new Audio('/assets/audio/music.mp3');;
  // musicaAmbiente:any = new Audio('/assets/audio/Sopa_Casual - Level 1 (Loop_02).mp3');;
  //musicaAmbiente:any = new Audio('/assets/audio/loop_test.mp3');
  //musicaAmbiente:any = new Audio('/assets/audio/Sopa_Casual - Level 1 (Loop_02).ogg');
  musicaAmbiente:any = new Howl({
    src: ['/assets/audio/Sopa_Casual - Level 1 (Loop_02).ogg'],
    loop: true,
    volume: 0.4
  });
  audio_abrir_en_chrome = new Audio('/assets/audio/abrir_en_chrome.mp3');
  audio_girar_dispositivo = new Audio('/assets/audio/girar_dispositivo.mp3');
  //listado de frases audio
  arrayAudioFrasesCorrectas:any[] = [];
  arrayAudioFrasesCorrectasString:string[] = ["bien.mp3",
  "correcto_muy_bien.mp3",
  "excelente.mp3",
  "genial_super_bien.mp3",
  "super_bien.mp3"];

  arrayAudioFrasesIncorrectas:any[];
  arrayAudioFrasesIncorrectasString:string[] = ["animo_prueba_de_nuevo.mp3",
  "animo_tu_puedes.mp3",
  "concentrate_un_poco_mas.mp3",
  "confio_en_ti.mp3",
  "intentalo_de_nuevo.mp3",
  "intentalo_una_vez_mas.mp3",
  "tu_puedes_lograrlo.mp3"];

  //listado de frases pasar nivel audio
  frasePasarNivel:any = new Audio('/assets/audio/frases/niveles/excelente_pasaste_el_nivel.mp3');
  fraseFallarNivel:any = new Audio('/assets/audio/frases/niveles/confio_en_ti_intentalo_de_nuevo.mp3');
  frasePasarTodosNiveles:any = new Audio('/assets/audio/frases/niveles/felicitaciones_pasaste_todos_niveles.mp3');
  CookieTipoTextoPalabras:any="";
  CookieTipoArticulo:any="";
  CookieMostrarPalabraMetodo:any="";
  CookieMostrarPalabraMetodo2:any="";
  UltimaRutaMetodo:any="";
  CookieMostrarArticuloMetodo:any="";
  CookieMostrarArticuloMetodo2:any="";
  CookieAudioInstruccion:any="";
  ActividadesEnLetrapps = false;
  popitSeleccionado = false;
  _ID_PESTANIA_:any="";
  swal:any=Swal;
  NOMBRE_JUEGO_ACTUAL:any = "";
  _KPI_:any = {
    _id: this._ID_PESTANIA_,
    FECHA_HORA: new Date().toLocaleString(),
    TEXTO: "",
    PLATAFORMA: "",
    TIPO: "",
    JUEGO_ACTIVIDAD: "",
    ACCIONES:[]
  };
  PlataformaOrigen = "LANDINGJE";

  base_url = "";
  ArrayCategorias:any=[
    {
      nombre: "Decodificación",
      src: "cat_decodificacion",
      tipo: "decodificacion",
      instruccion: "Esta categoría de juegos permite desarrollar la habilidad de decodificar al relacionar las letras y los sonidos para pronunciar correctamente las palabras escritas."
    },
    {
      nombre: "Conciencia Fonológica",
      src: "cat_conciencia_f",
      tipo: "conciencia_fonologica",
      instruccion: "Esta categoría de juegos permite desarrollar la habilidad para reflexionar y manipular de manera consciente segmentos del lenguaje oral."
    },
    {
      nombre: "Conciencia Silábica",
      src: "cat_conciencia",
      tipo: "conciencia_silabica",
      instruccion: "Esta categoría de juegos permite desarrollar uno de los ámbitos de la conciencia fonológica la cual hace referencia a la capacidad de comprender que el discurso hablado se divide en unas unidades sonoras más pequeñas llamadas sílabas y  que se pueden manipular. "
    },
    {
      nombre: "Velocidad Lectora",
      src: "cat_velocidad",
      tipo: "velocidad_lectora",
      instruccion: "Esta categoría de juegos permite desarrollar la habilidad de leer un cierto número de palabras por minuto por medio de diversas estrategias lúdicas."
    },
    {
      nombre: "Comprensión Lectora",
      src: "cat_comprension",
      tipo: "comprension_lectora",
      instruccion: "Esta categoría de juegos permite por medio de diversas estrategias desarrollar el proceso cognitivo orientado a entender el significado de un texto."
    },
    {
      nombre: "Ortografía",
      src: "cat_ortografia",
      tipo: "ortografia",
      instruccion: "Esta categoría de juegos permite desarrollar el conjunto de normas y reglas que regulan la escritura de palabras."
    },
    {
      nombre: "Grafomotricidad",
      src: "cat_grafomotricidad",
      tipo: "grafomotricidad",
      instruccion: "Esta categoría de actividades permite desarrollar en los estudiantes los  movimientos que deben realizar con  la mano para cumplir una determinada actividad o tarea."
    },
    {
      nombre: "Lengua de Señas",
      src: "juego_21",
      tipo: "juegos_lsch",
      instruccion: "Esta categoría de juegos permite aprender diversas palabras y frases en lengua de señas, especialmente orientado al contexto educativo."
    }

  ];
  ArrayJuegosLsch:any=[
    {
      src:"juego_19",
      nombre: 'trivia-lsch',
      url: "seleccion-categoria-trivia",
      categoria: 'juegos_lsch',
      nombre_oficial: 'Trivia de Señas',
      instruccion: 'El tradicional juego de la trivia consiste en visualizar un video de una palabra en Lengua de Señas Chilena, y reconocer la correcta de forma escrita entre 4 alternativas.',
      mecanica: 'Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta.',
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      src:"juego_20",
      nombre: 'trivia-frases',
      url: "trivia-frases",
      categoria: 'juegos_lsch',
      nombre_oficial: 'Trivia Frases',
      instruccion: 'El tradicional juego de la trivia consiste en visualizar un video de una frase en lengua de señas chilena, y encontrar la correcta de forma escrita de entre 4 alternativas.',
      mecanica: 'Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta.',
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      src:"juego_24",
      nombre: 'verdadero-falso',
      url: "seleccion-categoria-trivia",
      categoria: 'juegos_lsch',
      nombre_oficial: 'Verdadero o Falso',
      instruccion: 'Este juego consiste en  encontrar el video de señas correcto asociado a una palabra determinada.',
      mecanica: 'Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta.',
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      src:"juego_25",
      nombre: 'memoriza-senas',
      url: "seleccion-categoria-trivia",
      categoria: 'juegos_lsch',
      nombre_oficial: 'MemoriSeñas',
      instruccion: 'El juego consiste en memorizar parejas de tarjetas entre la palabra escrita y el video en lengua de señas correspondiente a ella',
      mecanica: 'Debes usar el mouse para seleccionar las parejas correctas. En celulares y tablet debes tocar la respuesta correcta.',
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
  ];
  ArrayJuegosLt:any=[
    {
      nombre: "abc-globos",
      nombre_oficial: "ABC de los Globos",
      src:"abc-globos",
      url: "Integracion_Letrapps_ABC_Globos/abc-globos",
      categoria: ["decodificacion"],
      instruccion: "El juego consiste en reventar todos aquellos globos que posean vocales, consonantes, mayúsculas y minúsculas, según sea en cada pregunta.",
      potencia: "",
      mecanica: "Debes usar el mouse para reventar los globos. En móviles, debes tocar los globos.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "momiletras",
      nombre_oficial: "MomiLetras",
      src:"momiletras",
      especial: "halloween",
      url: "Integracion_Letrapps_MomiLetras",
      categoria: ["decodificacion"],
      instruccion: "El tradicional juego del desarmado consiste en encontrar las letras escondidas que son parte de una palabra incógnita, antes que se acabe el tiempo.",
      potencia: "",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En celulares y tablet debes tocar las letras de cada palabra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "sopa",
      nombre_oficial: "Sopa de letras",
      src: this.isModoNavidad?"sopa_navidad":"sopa_landing",
      isNavidad: false,
      url: "Integracion_Letrapps_Sopa_de_Letras",
      categoria: ["velocidad_lectora","decodificacion"],
      instruccion: "El tradicional juego de la sopa de letras consiste en encontrar diversas palabras que están escondidas en distintas direcciones.",
      potencia: "Te permitirá:<br>• Favorecer la producción correcta de las palabras<br>• Incrementar el vocabulario.<br>• Aumentar la fluidez.<br>• Estructurar oraciones con las palabras encontradas.<br>• Realizar actividades de conciencia silábica y fonémica.",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "popit",
      nombre_oficial: "Pop It Lector",
      src: this.isModoNavidad?"popit_navidad":"popit_landing",
      isNavidad: false,
      url: "Integracion_Letrapps_Pop_It",
      categoria: ["decodificacion"],
      instruccion: "El juego consiste en encontrar las letras que están desordenadas en el pop-it, para formar las palabras que se indican a un costado.",
      potencia: "Te permitirá:<br>• Practicar actividades de conciencia silábica, realizando ejercicios de segmentación silábica, reconocimiento de sílaba inicial y final, e inversión silábica. <br>• Reforzar la conciencia fonémica, desarrollando actividades de identificación de los fonemas constituyentes, de análisis y síntesis fonémica.",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "silabario",
      nombre_oficial: "Silabario Inclusivo",
      src:"silabario_landing",
      url: "https://www.tabulatest.cl/SilabarioMusicalDemo/EntrenamientoSimon",
      categoria: ["conciencia_silabica","decodificacion","conciencia_fonologica"],
      instruccion: "Este juego consiste en  memorizar el orden de las sílabas, similar al juego “Simón dice”.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar las sílabas. En móviles, debes tocarlas.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"'
    },
    {
      nombre: "desarmado",
      nombre_oficial: "Desarmado",
      src:"ahorcado_landing",
      url: "Integracion_Letrapps_Desarmado",
      categoria: ["decodificacion"],
      instruccion: "El tradicional juego del desarmado consiste en encontrar las letras escondidas que son parte de una palabra incógnita, antes que se acabe el tiempo.",
      potencia: "",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras de cada palabra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Ruleta",
      nombre_oficial: "Ruleta de palabras",
      src:"ruleta_landing",
      url: "Integracion_Letrapps_Ruleta",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en encontrar la letra que sobra en cada palabra. Para ello se debe girar la ruleta que entregará una palabra al azar, de la cual se extrae la letra sobrante, arrastrándola a la cajita.",
      potencia: "",
      mecanica: "Debes usar el mouse praa arrastrar las letras. En móviles, debes tocar para arrastrar cada letra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Tomboletras",
      nombre_oficial: "Tomboletras",
      src:"tomboletras_landing",
      url: "Integracion_Letrapps_Tomboletras",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en reordenar las letras para encontrar la palabra incógnita. Para comenzar, se debe girar la tómbola que entregará una palabra al azar, la cual deberás reordenar.",
      potencia: "",
      mecanica: "Debes usar el mouse para mover las tarjetas con las letras de cada palabra. En móviles, debes tocar para arrastrar cada letra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "veloci-teclado",
      nombre_oficial: "Velociteclado",
      src:"veloci-teclado",
      url: "Integracion_Letrapps_VelociTeclado",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en escribir en el teclado del computador correctamente las letras, sílabas y palabras que aparecen en cada globo.",
      potencia: "",
      ocultar_qr: false,
      mecanica: "Debes usar las teclas del teclado del computador para escribir la respuesta correcta. En móviles, debes escribir con el teclado.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velociletras",
      nombre_oficial: "Velociletras",
      src:"juego_23",
      url: "Integracion_Letrapps_VelociLetras/velociletras",
      categoria: ["conciencia_fonologica","velocidad_lectora"],
      instruccion: "El juego consiste en leer ciertas letras con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada letra. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velocisilabas",
      nombre_oficial: "Velocisílabas",
      src:"juego_22",
      url: "Integracion_Letrapps_VelociSilabas",
      categoria: ["conciencia_fonologica","velocidad_lectora"],
      instruccion: "El juego consiste en leer ciertas sílabas con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada sílaba. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velocipalabras",
      nombre_oficial: "Velocipalabras",
      src:"juego_10",
      url: "Integracion_Letrapps_VelociPalabras",
      categoria: ["velocidad_lectora","conciencia_fonologica"],
      instruccion: "El juego consiste en leer ciertas palabras con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada palabra. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: 'Come-Silabas',
      nombre_oficial: "Serpiente come sílabas",
      src:"comesilabas",
      url: "Integracion_Letrapps_SerpienteComeSilabas",
      categoria: ["decodificacion","conciencia_silabica"],
      instruccion: "El juego consiste en que la serpiente debe identificar las sílabas y comerlas de acuerdo al orden en que forman la palabra indicada, teniendo cuidado de que otras serpientes no le quiten su comida.",
      potencia: "",
      mecanica: "Debes usar el mouse o las flechas del teclado para mover la serpiente. La velocidad turbo es con la barra espaciadora. En móviles, debes tocar la pantalla para moverte.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "separa-silabas",
      nombre_oficial: "Separa sílabas",
      src:"separa_silabas_landing",
      url: "Integracion_Letrapps_Separa_Silabas",
      categoria: ["conciencia_silabica","conciencia_fonologica"],
      instruccion: "El juego consiste en separar correctamente en sílabas las diversas palabras que se van presentando.",
      potencia: "",
      mecanica: "Debes usar el mouse para separar las sílabas de cada palabra. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Jet-Lector",
      nombre_oficial: "JetLector",
      src:"jetlector",
      url: "Integracion_Letrapps_JetLector",
      categoria: ["velocidad_lectora","ortografia"],
      instruccion: "El juego consiste en hacer que el Jet le acierte a diversas palabras, ya sea chocándolas o lanzándole sus estrellas, según la acentuación que se indique como objetivo (agudas, graves, esdrújula y sobreesdrújulas.)",
      potencia: "",
      mecanica: "Debes usar el mouse para reventar los globos. En móviles, debes tocar los globos de cada palabra. <br>Aún no disponible para jugar en móvil.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado_flecha_barra.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "nave-interlectora",
      nombre_oficial: "Nave interlectora",
      src:"nave_interlectora",
      url: "Integracion_Letrapps_Nave_Interlectora",
      categoria: ["ortografia"],
      instruccion: "El juego se trata de una nave intergaláctica que debe tocar o lanzar sus estrellas a las  palabras correctas, según la cantidad de sílabas que se indique. (monosílabas, bisílabas, polisílabas).",
      potencia: "",
      mecanica: "Debes usar el mouse para reventar los globos. En móviles, debes tocar los globos de cada palabra. <br>Aún no disponible para jugar en móvil.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado_flecha_barra.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "completa-oraciones",
      nombre_oficial: "Completar oraciones",
      src:"juego_07",
      url: "Integracion_Letrapps_CompletaOraciones",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego completar algunas palabras que estarán ocultas en las oraciones.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta. En móviles, debes arrastrar la respuesta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "orden-cronologico",
      nombre_oficial: "Orden cronológico de oraciones",
      src:"juego_08",
      url: "Integracion_JuegoEduca_OrdenCronologico",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego ordenar de forma cronológica  algunas oraciones que serán desordenadas aleatoriamente.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la oración correcta. En móviles, debes arrastrar la respuesta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "ordenar-oraciones",
      nombre_oficial: "Ordenar oraciones",
      src:"juego_09",
      url: "Integracion_JuegoEduca_OrdenarOracion",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego completar todas las palabras faltantes de una oración seleccionada de forma aleatoria.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta. En móviles, debes arrastrar la respuesta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "vbw",
      nombre_oficial: "¿V o B?",
      src:"juego_uso_vbw_landing",
      url: "Integracion_Letrapps_Uso_Letras/vb",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra V o B, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "jg",
      nombre_oficial: "¿J o G?",
      src:"juego_uso_jg_landing",
      url: "Integracion_Letrapps_Uso_Letras/jg",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra J o G, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "yll",
      nombre_oficial: "¿Y o LL?",
      src:"juego_uso_yll_landing",
      url: "Integracion_Letrapps_Uso_Letras/yll",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra Y o LL, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "rr",
      nombre_oficial: "¿R o RR?",
      src:"juego_uso_rr_landing",
      url: "Integracion_Letrapps_Uso_Letras/rr",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra R o RR, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "csz",
      nombre_oficial: "¿C, S o Z?",
      src:"juego_uso_csz_landing",
      url: "Integracion_Letrapps_Uso_Letras/csz",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra C, S o Z, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "mn",
      nombre_oficial: "¿M o N?",
      src:"juego_uso_mn_landing",
      url: "Integracion_Letrapps_Uso_Letras/mn",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra M o N, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "h",
      nombre_oficial: "Uso de H",
      src:"juego_uso_h_landing",
      url: "Integracion_Letrapps_Uso_Letras/letrah",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra H, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el teclado del computador para escribir la palabra  con h cuando corresponda. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "lleva-tilde",
      nombre_oficial: "¿Lleva tilde?",
      src:"acentuar_palabras_landing",
      url: "Integracion_Letrapps_Uso_Letras/lleva_tilde",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en asignar correctamente las tildes a diversas palabras, en tanto corresponda y según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar si lleva tilde. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    } , 
    {
      nombre: "grafotexto",
      nombre_oficial: "Grafo Textos",
      src:"juego_grafot",
      url: "https://letrapps.cl/GrafoTextoDemo",
      categoria: ["grafomotricidad"],
      instruccion: "Esta herramienta permite crear actividades imprimibles de grafomotricidad, en base a letras, palabras u oraciones.",
      potencia: "",
      mecanica: "Solo disponible en versión para computador.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;">'
    },
  ];
  ArrayJuegos:any=[
    {
      nombre: "maquina",
      nombre_oficial: "Máquina de lecturas",
      src:"maquina_landing",
      url: "maquina",
      categoria: ["decodificacion","comprension_lectora"],
      instruccion: "Este mágico juego permite que con un solo texto se puedan generan más de 10 juegos, transformando la clase en entretenidas actividades lectoras.",
      mecanica: "Debes usar el mouse  para todos los juegos. En algunos se usan las flechas del teclado.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "momiletras",
      nombre_oficial: "MomiLetras",
      src:"momiletras",
      especial: "halloween",
      url: "Integracion_JuegoEduca_MomiLetras",
      categoria: ["decodificacion"],
      instruccion: "El tradicional juego del desarmado consiste en encontrar las letras escondidas que son parte de una palabra incógnita, antes que se acabe el tiempo.",
      potencia: "",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En celulares y tablet debes tocar las letras de cada palabra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "abc-globos",
      nombre_oficial: "ABC de los Globos",
      src:"abc-globos",
      url: "Integracion_JuegoEduca_ABC_Globos/abc-globos",
      categoria: ["velocidad_lectora"],
      instruccion: "El juego consiste en reventar todos aquellos globos que posean vocales, consonantes, mayúsculas y minúsculas, según sea en cada pregunta.",
      potencia: "",
      mecanica: "Debes usar el mouse para reventar los globos. En móviles, debes tocar los globos.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "sopa",
      nombre_oficial: "Sopa de letras",
      src: this.isModoNavidad?"sopa_navidad":"sopa_landing",
      isNavidad: false,
      url: "Integracion_JuegoEduca_Sopa_de_Letras",
      categoria: ["velocidad_lectora","decodificacion"],
      instruccion: "El tradicional juego de la sopa de letras consiste en encontrar diversas palabras que están escondidas en distintas direcciones.",
      potencia: "Te permitirá:<br>• Favorecer la producción correcta de las palabras<br>• Incrementar el vocabulario.<br>• Aumentar la fluidez.<br>• Estructurar oraciones con las palabras encontradas.<br>• Realizar actividades de conciencia silábica y fonémica.",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "popit",
      nombre_oficial: "Pop It Lector",
      src: this.isModoNavidad?"popit_navidad":"popit_landing",
      isNavidad: false,
      url: "Integracion_JuegoEduca_Pop_It",
      categoria: ["decodificacion"],
      instruccion: "El juego consiste en encontrar las letras que están desordenadas en el pop-it, para formar las palabras que se indican a un costado.",
      potencia: "Te permitirá:<br>• Practicar actividades de conciencia silábica, realizando ejercicios de segmentación silábica, reconocimiento de sílaba inicial y final, e inversión silábica. <br>• Reforzar la conciencia fonémica, desarrollando actividades de identificación de los fonemas constituyentes, de análisis y síntesis fonémica.",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "silabario",
      nombre_oficial: "Silabario Inclusivo",
      src:"silabario_landing",
      url: "https://www.tabulatest.cl/SilabarioMusicalDemo/EntrenamientoSimon",
      categoria: ["conciencia_silabica","decodificacion","conciencia_fonologica"],
      instruccion: "Este juego consiste en  memorizar el orden de las sílabas, similar al juego “Simón dice”.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar las sílabas. En móviles, debes tocarlas.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "karaoke",
      nombre_oficial: "Karaoke Lector",
      src:"juego_karaoke",
      url: "https://letrapps.cl/KaraokeLectorDemo",
      categoria: ["decodificacion"],
      instruccion: "Transforma las clases de lectura en entretenidas actividades lúdicas, subiendo un texto o seleccionándolo del programa Leo Primero. Podrás cambiar colores y tipos de letra, tamaños, evaluar velocidad lectora, grabar audio del estudiante, usar efecto Karaoke y al finalizar, tendrás disponible entretenidos juegos automatizados del mismo texto. Puedes utilizarlos en clases y como apoyo en los hogares.",
      potencia: "",
      ocultar_qr: false,
      mecanica: "Solo disponible en versión para computador.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;">'
    },
    {
      nombre: "desarmado",
      nombre_oficial: "Desarmado",
      src:"ahorcado_landing",
      url: "Integracion_JuegoEduca_Desarmado",
      categoria: ["decodificacion"],
      instruccion: "El tradicional juego del desarmado consiste en encontrar las letras escondidas que son parte de una palabra incógnita, antes que se acabe el tiempo.",
      potencia: "",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En celulares y tablet debes tocar las letras de cada palabra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Ruleta",
      nombre_oficial: "Ruleta de palabras",
      src:"ruleta_landing",
      url: "Integracion_JuegoEduca_Ruleta",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en encontrar la letra que sobra en cada palabra. Para ello se debe girar la ruleta que entregará una palabra al azar, de la cual se extrae la letra sobrante, arrastrándola a la cajita.",
      potencia: "",
      mecanica: "Debes usar el mouse para arrastrar las letras. En móviles, debes tocar para arrastrar cada letra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Tomboletras",
      nombre_oficial: "Tomboletras",
      src:"tomboletras_landing",
      url: "Integracion_JuegoEduca_Tomboletras",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en reordenar las letras para encontrar la palabra incógnita. Para comenzar, se debe girar la tómbola que entregará una palabra al azar, la cual deberás reordenar.",
      potencia: "",
      mecanica: "Debes usar el mouse para mover las tarjetas con las letras de cada palabra. En móviles, debes tocar para arrastrar cada letra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "veloci-teclado",
      nombre_oficial: "Velociteclado",
      src:"veloci-teclado",
      url: "Integracion_JuegoEduca_VelociTeclado",
      categoria: ["velocidad_lectora"],
      instruccion: "El juego consiste en escribir en el teclado del computador correctamente las letras, sílabas y palabras que aparecen en cada globo.",
      potencia: "",
      ocultar_qr: false,
      mecanica: "Debes usar las teclas del teclado del computador para escribir la respuesta correcta. En móviles, debes escribir con el teclado.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velociletras",
      nombre_oficial: "Velociletras",
      src:"juego_23",
      url: "Integracion_JuegoEduca_VelociLetras/velociletras",
      categoria: ["conciencia_fonologica","velocidad_lectora"],
      instruccion: "El juego consiste en leer ciertas letras con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada letra. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velocisilabas",
      nombre_oficial: "Velocisílabas",
      src:"juego_22",
      url: "Integracion_JuegoEduca_VelociSilabas",
      categoria: ["conciencia_fonologica","velocidad_lectora"],
      instruccion: "El juego consiste en leer ciertas sílabas con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada sílaba. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velocipalabras",
      nombre_oficial: "Velocipalabras",
      src:"juego_10",
      url: "Integracion_JuegoEduca_VelociPalabras",
      categoria: ["velocidad_lectora","conciencia_fonologica"],
      instruccion: "El juego consiste en leer ciertas palabras con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada palabra. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Come-Silabas",
      nombre_oficial: "Serpiente come sílabas",
      src:"comesilabas",
      url: "Integracion_JuegoEduca_SerpienteComeSilabas",
      categoria: ["conciencia_silabica"],
      instruccion: "El juego consiste en que la serpiente debe identificar las sílabas y comerlas de acuerdo al orden en que forman la palabra indicada, teniendo cuidado de que otras serpientes no le quiten su comida.",
      potencia: "",
      mecanica: "Debes usar el mouse o las flechas del teclado para mover la serpiente. La velocidad turbo es con la barra espaciadora. En móviles, debes tocar la pantalla para moverte.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado_flecha.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "separa-silabas",
      nombre_oficial: "Separa sílabas",
      src:"separa_silabas_landing",
      url: "Integracion_JuegoEduca_Separa_Silabas",
      categoria: ["conciencia_silabica","conciencia_fonologica"],
      instruccion: "El juego consiste en separar correctamente en sílabas las diversas palabras que se van presentando.",
      potencia: "",
      mecanica: "Debes usar el mouse para separar las sílabas de cada palabra. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Jet-Lector",
      nombre_oficial: "JetLector",
      src:"jetlector",
      url: "Integracion_JuegoEduca_JetLector",
      categoria: ["velocidad_lectora","ortografia"],
      instruccion: "El juego consiste en hacer que el Jet le acierte a diversas palabras, ya sea chocándolas o lanzándole sus estrellas, según la acentuación que se indique como objetivo (agudas, graves, esdrújula y sobreesdrújulas.)",
      potencia: "",
      mecanica: "Debes usar el mouse para reventar los globos. En celulares y tablet debes tocar los globos de cada palabra. <br>Aún no disponible para jugar en móvil.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado_flecha_barra.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "nave-interlectora",
      nombre_oficial: "Nave interlectora",
      src:"nave_interlectora",
      url: "Integracion_JuegoEduca_Nave_Interlectora",
      categoria: ["velocidad_lectora"],
      instruccion: "El juego se trata de una nave intergaláctica que debe tocar o lanzar sus estrellas a las  palabras correctas, según la cantidad de sílabas que se indique. (monosílabas, bisílabas, polisílabas).",
      potencia: "",
      mecanica: "Debes usar el mouse para reventar los globos. En celulares y tablet debes tocar los globos de cada palabra. <br>Aún no disponible para jugar en móvil.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado_flecha_barra.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "completa-oraciones",
      nombre_oficial: "Completar oraciones",
      src:"juego_07",
      url: "Integracion_JuegoEduca_CompletaOraciones",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego completar algunas palabras que estarán ocultas en las oraciones.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta. En celulares y tablet debes arrastrar la respuesta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "orden-cronologico",
      nombre_oficial: "Orden cronológico de oraciones",
      src:"juego_08",
      url: "Integracion_JuegoEduca_OrdenCronologico",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego ordenar de forma cronológica  algunas oraciones que serán desordenadas aleatoriamente.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la oración correcta. En celulares y tablet debes arrastrar la respuesta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "ordenar-oraciones",
      nombre_oficial: "Ordenar oraciones",
      src:"juego_09",
      url: "Integracion_JuegoEduca_OrdenarOracion",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego completar todas las palabras faltantes de una oración seleccionada de forma aleatoria.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta. En celulares y tablet debes arrastrar la respuest",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    }, 
    {
      nombre: "vbw",
      nombre_oficial: "¿V o B?",
      src:"juego_uso_vbw_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/vb",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra V o B, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "jg",
      nombre_oficial: "¿J o G?",
      src:"juego_uso_jg_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/jg",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra J o G, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "yll",
      nombre_oficial: "¿Y o LL?",
      src:"juego_uso_yll_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/yll",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra Y o LL, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "rr",
      nombre_oficial: "¿R o RR?",
      src:"juego_uso_rr_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/rr",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra R o RR, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "csz",
      nombre_oficial: "¿C, S o Z?",
      src:"juego_uso_csz_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/csz",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra C, S o Z, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "mn",
      nombre_oficial: "¿M o N?",
      src:"juego_uso_mn_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/mn",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra M o N, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la letra correcta. En celulares y tablet debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "h",
      nombre_oficial: "Uso de H",
      src:"juego_uso_h_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/letrah",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en completar diversas palabras con la letra H, según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el teclado del computador para escribir la palabra  con h cuando corresponda. En celulares y tablet debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "lleva-tilde",
      nombre_oficial: "¿Lleva tilde?",
      src:"acentuar_palabras_landing",
      url: "Integracion_JuegoEduca_Uso_Letras/lleva_tilde",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en asignar correctamente las tildes a diversas palabras, en tanto corresponda y según las reglas ortográficas correspondientes.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar si lleva tilde. En celulares y tablet debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "grafotexto",
      nombre_oficial: "Grafo Textos",
      src:"juego_grafot",
      url: "https://letrapps.cl/GrafoTextoDemo",
      categoria: ["grafomotricidad"],
      instruccion: "Esta herramienta permite crear actividades imprimibles de grafomotricidad, en base a letras, palabras u oraciones.",
      potencia: "",
      ocultar_qr: false,
      mecanica: "Solo disponible en versión para computador.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;">'
    },
    // {
    //   nombre: "golpea-al-virus",
    //   nombre_oficial: "Golpea al Virus",
    //   src:"golpea-al-virus",
    //   url: "Integracion_JuegoEduca_GolpeaAlVirus",
    //   categoria: ["velocidad_lectora"],
    //   instruccion: "...",
    //   potencia: "",
    //   ocultar_qr: false,
    //   mecanica: "....",
    //   imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    // },
  ];
  ArrayJuegosMaquina:any=[
    // {
    //   nombre: "abc-globos",
    //   nombre_oficial: "ABC de los Globos",
    //   src:"abc-globos",
    //   url: "abc-globos",
    //   categoria: ["velocidad_lectora"],
    //   instruccion: "El juego consiste en reventar todos aquellos globos que posean vocales, consonantes, mayúsculas y minúsculas, según sea cada pregunta.",
    //   potencia: "",
    //   mecanica: "Debes usar el mouse para reventar los globos. En móviles debes tocar los globos.",
    //   imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    // },
    {
      nombre: "sopa",
      nombre_oficial: "Sopa de letras",
      src:"sopa_maquina",
      url: "sopa-de-letras",
      categoria: ["velocidad_lectora","decodificacion"],
      instruccion: "El tradicional juego de la sopa de letras consiste en encontrar diversas palabras que están escondidas en distintas direcciones.",
      potencia: "Te permitirá:<br>• Favorecer la producción correcta de las palabras<br>• Incrementar el vocabulario.<br>• Aumentar la fluidez.<br>• Estructurar oraciones con las palabras encontradas.<br>• Realizar actividades de conciencia silábica y fonémica.",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "popit",
      nombre_oficial: "Pop It Lector",
      src:"popit_maquina",
      url: "pop-it",
      categoria: ["decodificacion"],
      instruccion: "El juego consiste en encontrar las letras que están desordenadas en el pop-it, para formar las palabras que se indican a un costado.",
      potencia: "Te permitirá:<br>• Practicar actividades de conciencia silábica, realizando ejercicios de segmentación silábica, reconocimiento de sílaba inicial y final, e inversión silábica. <br>• Reforzar la conciencia fonémica, desarrollando actividades de identificación de los fonemas constituyentes, de análisis y síntesis fonémica.",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "desarmado",
      nombre_oficial: "Desarmado",
      src:"ahorcado_maquina",
      url: "desarmado",
      categoria: ["decodificacion"],
      instruccion: "El tradicional juego del desarmado consiste en encontrar las letras escondidas que son parte de una palabra incógnita, antes que se acabe el tiempo.",
      potencia: "",
      mecanica: "Debes usar el mouse para marcar las letras de cada palabra. En móviles, debes tocar las letras de cada palabra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Ruleta",
      nombre_oficial: "Ruleta de palabras",
      src:"ruleta_maquina",
      url: "ruleta",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en encontrar la letra que sobra en cada palabra. Para ello se debe girar la ruleta que entregará una palabra al azar, de la cual se extrae la letra sobrante, arrastrándola a la cajita.",
      potencia: "",
      mecanica: "Debes usar el mouse para arrastrar las letras. En móviles, debes tocar para arrastrar cada letra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Tomboletras",
      nombre_oficial: "Tomboletras",
      src:"tomboletras_maquina",
      url: "tomboletras",
      categoria: ["ortografia"],
      instruccion: "El juego consiste en reordenar las letras para encontrar la palabra incógnita. Para comenzar, se debe girar la tómbola que entregará una palabra al azar, la cual deberás reordenar.",
      potencia: "",
      mecanica: "Debes usar el mouse para mover las tarjetas con las letras de cada palabra. En móviles, debes tocar para arrastrar cada letra.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    // {
    //   nombre: "veloci-teclado",
    //   nombre_oficial: "Velociteclado",
    //   src:"velociteclado_maquina",
    //   url: "veloci-teclado",
    //   categoria: ["velocidad_lectora"],
    //   instruccion: "El juego consiste en escribir en el teclado del computador correctamente las letras, sílabas y palabras que aparecen en cada globo.",
    //   potencia: "",
    //   mecanica: "Debes usar las teclas del teclado del computador para escribir la respuesta correcta. En móviles, debes escribir con el teclado.",
    //   imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    // },
    {
      nombre: "velociletras",
      nombre_oficial: "Velociletras",
      src:"velociletras_maquina",
      url: "velociletras",
      categoria: ["conciencia_fonologica","velocidad_lectora"],
      instruccion: "El juego consiste en leer ciertas letras con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada letra. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velocisilabas",
      nombre_oficial: "Velocisílabas",
      src:"velocisilabas_maquina",
      url: "velocisilabas",
      categoria: ["conciencia_fonologica","velocidad_lectora"],
      instruccion: "El juego consiste en leer ciertas sílabas con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada sílaba. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "velocipalabras",
      nombre_oficial: "Velocipalabras",
      src:"velocipalabras_maquina",
      url: "velocipalabras",
      categoria: ["velocidad_lectora","conciencia_fonologica"],
      instruccion: "El juego consiste en leer ciertas palabras con una velocidad inicial, de las cuales  debes recordar la última en aparecer. La velocidad irá aumentando o descendiendo de acuerdo a lo respondido.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta en cada palabra. En móviles, debes tocar la respuesta correcta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "Come-Silabas",
      nombre_oficial: "Serpiente come sílabas",
      src:"serpiente_maquina",
      url: "serpiente-come-silabas",
      categoria: ["conciencia_silabica"],
      instruccion: "El juego consiste en que la serpiente debe identificar las sílabas y comerlas de acuerdo al orden en que forman la palabra indicada, teniendo cuidado de que otras serpientes no le quiten su comida.",
      potencia: "",
      mecanica: "Debes usar el mouse o las flechas del teclado para mover la serpiente, también posee velocidad turbo que se activa con la barra espaciadora. En móviles, debes tocar la pantalla para moverte.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_teclado_flecha.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "separa-silabas",
      nombre_oficial: "Separa sílabas",
      src:"separa_silabas_maquina",
      url: "juego-separa-palabra-silabas",
      categoria: ["conciencia_silabica","conciencia_fonologica"],
      instruccion: "El juego consiste en separar correctamente en sílabas las diversas palabras que se van presentando.",
      potencia: "",
      mecanica: "Debes usar el mouse para separar las sílabas de cada palabra. En móviles, debes tocar la respuesta correcta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "completa-oraciones",
      nombre_oficial: "Completar oraciones",
      src:"completar_oraciones_maquina",
      url: "completa-oraciones",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego completar algunas palabras que estarán ocultas en las oraciones.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta. En móviles, debes arrastrar la respuesta.",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "orden-cronologico",
      nombre_oficial: "Orden cronológico de oraciones",
      src:"orden_cronologico_maquina",
      url: "orden-cronologico",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego ordenar de forma cronológica  algunas oraciones que serán desordenadas aleatoriamente.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la oración correcta. En móviles, debes arrastrar la respuesta",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    },
    {
      nombre: "ordenar-oraciones",
      nombre_oficial: "Ordenar oraciones",
      src:"ordenar_oraciones_maquina",
      url: "ordenar-oraciones",
      categoria: ["comprension_lectora"],
      instruccion: "El juego consiste en leer un texto determinado, para luego completar todas las palabras faltantes de una oración seleccionada de forma aleatoria.",
      potencia: "",
      mecanica: "Debes usar el mouse para seleccionar la alternativa correcta. En móviles, debes arrastrar la respuest",
      imagen: '<img src="assets/images/btn_mouse.png" style="width: 95px;"><img src="assets/images/btn_touch.png" style="width: 95px;">'
    }
  ];
  //SABER SI ES NAVEGADOR INSTAGRAM
  ua:any = navigator.userAgent || navigator.vendor;
  isInstagram :any = (this.ua.indexOf('Instagram') > -1) ? true : false;
  isProduction:any=false;
  isLocal:any=false;
  constructor(private router: Router, private http: HttpClient, private Location:Location, public api:ApiService){ 
    let parent_url = document.referrer; // ORIGEN DEL IFRAME

    //CARGANDO INICIAL
    //URL
    this.base_url = window.location.origin;
    if(this.base_url == "https://juegoeduca.com" || this.base_url == "https://www.juegoeduca.com" || this.base_url == "https://test.juegoeduca.com" || this.base_url == "https://www.test.juegoeduca.com"){
      this.base_url = "https://letrapps.cl";
    }
    if(window.location.origin.indexOf("localhost")>=0) this.isLocal = true;
    this.isProduction=environment.production;

    if(parent_url.indexOf('tabulatest') > 0){
      this.EstoyEnTabulatest = true;
    }
    if(this.router.routerState.snapshot.url === "/"){
      $('#modalaso').show();
      $("#cargando_TBT").addClass("hidden");
      $("#cargando_LT").addClass("hidden");
      $("#cargando_JE").removeClass("hidden");
    }
    if(this.router.routerState.snapshot.url === "/seleccion-juegos"){
      $('#modalaso').show();
      $("#cargando_JE").addClass("hidden");
      $("#cargando_LT").addClass("hidden");
      $("#cargando_TBT").addClass("hidden");
      if(this.EstoyEnTabulatest){
        $("#cargando_TBT").removeClass("hidden");
        $("#cargando_LT").addClass("hidden");
      }else{
        $("#cargando_LT").removeClass("hidden");
      }
    }

    //BOTON JUGAR DEL NUEVO MODAL DE INSTRUCCIONES DE LOS JUEGOS
    $('#btn-jugar-modal').on('click', e => {
      this.jugar();
    })

    // if(!this.isInstagram){
    //   $("#mensaje_instagram").addClass("hidden");
    // }

    this.getTextoActual();
    this.getTipoIngreso();
    this.getTipoFuentePDF();
    this.getCookieCursoLeoPrimero();
    this.getCookieTomoLeoPrimero();
    this.getCookieClaseLeoPrimero();
    this.getCookieLecturaLeoPrimero();
    this.getCookieTipoTextoPalabras();
    this.getCookieAudioInstruccion();
    // this.create_user();
    this.getLocalStorage('CookieTipoArticulo','ARTICULO_DEFINIDO');
    this.getLocalStorage('CookieMostrarPalabraMetodo','false');
    this.getLocalStorage('CookieMostrarPalabraMetodo2','true');
    this.getLocalStorage('CookieMostrarArticuloMetodo','false');
    this.getLocalStorage('CookieMostrarArticuloMetodo2','true');
    this.getLocalStorage('UltimaRutaMetodo','');
    
    //WEBINAR
    if(this.router.routerState.snapshot.url == "/webinar")window.location.href = 'https://tabulatest.cl/webinar/JE';


    this.getConfiguracionTemporalJuego();
    $(window).resize(event=>{
      $(".panel").css({height: window.outerHeight});
    });

    this.verificarIntegracionLetrapps();
    this.verificarIntegracionJuegoEduca();
    this.verificarPlataformaActual();
    this.cargarAudioFrases();

    //
    this._ID_PESTANIA_ = ObjectID().toHexString();
    var config = {
      apiKey: "AIzaSyAGYs_2nWgPEq5L-cb4TQa0aiNyTo8Zfwo", 
      authDomain: "integritic-5b357.firebaseapp.com", 
      databaseURL: "https://integritic-5b357.firebaseio.com", 
      projectId: "integritic-5b357", 
      storageBucket: "integritic-5b357.appspot.com", 
      messagingSenderId: "671460886134"
    };
    firebase.initializeApp(config);

    GLOBAL = this;
    $(window).on('orientationchange', function(event) {
      // console.error("ORIENTATION GLOBAL");
      // console.error(window.location);
      //console.error(window.location.pathname);
      //console.error(screen.orientation);
      console.error(GLOBAL);
      //GLOBAL.audio_girar_dispositivo = new Audio('/assets/audio/girar_dispositivo.mp3');
      
      var es_maquina = (window.location.pathname.indexOf('maquina') > -1) ? true : false;
      var es_landing = (window.location.pathname == "/") ? true : false;
      var es_seleccion_juegos =  (window.location.pathname == "/seleccion-juegos-je") ? true : false;
      var es_act_imprimible = (window.location.pathname == "/actividades-para-imprimir/MAQUINA") ? true : false;
      var es_velociteclado = (window.location.pathname == "/Integracion_JuegoEduca_VelociTeclado/veloci-teclado") ? true : false;
      console.error(!es_maquina && !es_landing && !es_seleccion_juegos && !es_act_imprimible && !es_velociteclado)
      if(!es_maquina && !es_landing && !es_seleccion_juegos && !es_act_imprimible && !es_velociteclado){
        var es_juego = window.location.pathname.split("/");
        console.error(es_juego);
        if(es_juego[2]){
          if(es_juego[2] == "seleccion-palabras-categorias"){
            switch(screen.orientation.type) {
              case 'portrait-primary':
                $("#girar-dispositivo").removeClass("hidden");
                $("#contenido-seleccion-categorias-palabras ").addClass("hidden");
                //reproducir sonido
                GLOBAL.audio_girar_dispositivo.play();
                break;
              case 'landscape-primary':
                $("#girar-dispositivo").addClass("hidden");
                $("#contenido-seleccion-categorias-palabras ").removeClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              case 'landscape-secondary':
                $("#girar-dispositivo").addClass("hidden");
                $("#contenido-seleccion-categorias-palabras ").removeClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              default:  
            }
          }else if(es_juego[2] == "leo-primero"){
            console.error(screen);
            switch(screen.orientation.type) {
              case 'portrait-primary':
                $("#girar-dispositivo").removeClass("hidden");
                $("#panel-leo-primero").addClass("hidden");
                //reproducir sonido
                GLOBAL.audio_girar_dispositivo.play();       
                break;
              case 'landscape-primary':
                $("#girar-dispositivo").addClass("hidden");
                $("#panel-leo-primero").removeClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              case 'landscape-secondary':
                $("#girar-dispositivo").addClass("hidden");
                $("#panel-leo-primero").removeClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              default:  
            }
            setTimeout(()=>$("#panel-leo-primero").css({height: window.outerHeight+500}),100);
          }else if(es_juego[2] == "ingreso-texto"){
            switch(screen.orientation.type) {
              case 'portrait-primary':
                $("#girar-dispositivo").removeClass("hidden");
                $(".panel").addClass("hidden");
                //reproducir sonido
                GLOBAL.audio_girar_dispositivo.play();
                break;
              case 'landscape-primary':
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                $(".panel").removeClass("hidden");
                break;
              case 'landscape-secondary':
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                $(".panel").removeClass("hidden");
                break;
              default:  
            }
            setTimeout(()=>$(".panel").css({height: window.outerHeight+500}),100);
          }else if(es_juego[2] == "inicio"){
            switch(screen.orientation.type) {
              case 'portrait-primary':
                $("#girar-dispositivo").removeClass("hidden");
                $("#div-contenido").addClass("hidden");
                //reproducir sonido
                GLOBAL.audio_girar_dispositivo.play(); 
                break;
              case 'landscape-primary':
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                $("#div-contenido").removeClass("hidden");
                break;
              case 'landscape-secondary':
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                $("#div-contenido").removeClass("hidden");
                break;
              default:  
            }
          }else if(es_juego[1] == "MetodoGlobal"){
            console.log(screen.orientation.type)
            switch(screen.orientation.type) {
              case 'portrait-primary':
                $("#girar-dispositivo").removeClass("hidden");
                $("#div-contenido").addClass("hidden");
                //reproducir sonido
                GLOBAL.audio_girar_dispositivo.play(); 
                break;
              case 'landscape-primary':
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                $("#div-contenido").removeClass("hidden");
                break;
              case 'landscape-secondary':
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                $("#div-contenido").removeClass("hidden");
                break;
              default:  
            }
          }else{
            //JUEGO
            //console.error(es_juego[2])
            switch(screen.orientation.type) {
              case 'portrait-primary':
                $("canvas").css("display","none");
                $("#contenedor-barra").addClass("hidden");
                $("#dropdown-orientaciones").addClass("hidden");
                $("#girar-dispositivo").removeClass("hidden");
                if(es_juego[2] == "juego-separa-palabra-silabas"){
                  $(".contenedor-palabra").addClass("hidden");
                  $(".contenedor-fonema").addClass("hidden");
                }
                //reproducir sonido
                GLOBAL.audio_girar_dispositivo.play();    
                break;
              case 'landscape-primary':
                $("canvas").css("display","block");
                $("#dropdown-orientaciones").removeClass("hidden");
                $("#contenedor-barra").removeClass("hidden");
                if(es_juego[2] == "juego-separa-palabra-silabas"){
                  $(".contenedor-palabra").removeClass("hidden");
                  $(".contenedor-fonema").removeClass("hidden");
                }
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              case 'landscape-secondary':
                $("canvas").css("display","block");
                $("#dropdown-orientaciones").removeClass("hidden");
                $("#contenedor-barra").removeClass("hidden");
                if(es_juego[2] == "juego-separa-palabra-silabas"){
                  $(".contenedor-palabra").removeClass("hidden");
                  $(".contenedor-fonema").removeClass("hidden");
                }
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              default:  
            }
          }
        }else{
          //HOME
          if(es_juego[1]){
            if(es_juego[1] === "trivia-lsch" || es_juego[1] === "trivia-frases" || es_juego[1] === "verdadero-falso" || es_juego[1] === "memoriza-senas"){
              switch(screen.orientation.type) {
                case 'portrait-primary':
                  $("canvas").css("display","none");
                  $("#contenedor-barra").addClass("hidden");
                  $("#dropdown-orientaciones").addClass("hidden");
                  $("#girar-dispositivo").removeClass("hidden");
                  if(es_juego[2] == "juego-separa-palabra-silabas"){
                    $(".contenedor-palabra").addClass("hidden");
                    $(".contenedor-fonema").addClass("hidden");
                  }
                  //reproducir sonido
                  GLOBAL.audio_girar_dispositivo.play();    
                  break;
                case 'landscape-primary':
                  $("canvas").css("display","block");
                  $("#dropdown-orientaciones").removeClass("hidden");
                  $("#contenedor-barra").removeClass("hidden");
                  if(es_juego[2] == "juego-separa-palabra-silabas"){
                    $(".contenedor-palabra").removeClass("hidden");
                    $(".contenedor-fonema").removeClass("hidden");
                  }
                  $("#girar-dispositivo").addClass("hidden");
                  GLOBAL.audio_girar_dispositivo.pause();
                  GLOBAL.audio_girar_dispositivo.currentTime = 0;
                  break;
                case 'landscape-secondary':
                  $("canvas").css("display","block");
                  $("#dropdown-orientaciones").removeClass("hidden");
                  $("#contenedor-barra").removeClass("hidden");
                  if(es_juego[2] == "juego-separa-palabra-silabas"){
                    $(".contenedor-palabra").removeClass("hidden");
                    $(".contenedor-fonema").removeClass("hidden");
                  }
                  $("#girar-dispositivo").addClass("hidden");
                  GLOBAL.audio_girar_dispositivo.pause();
                  GLOBAL.audio_girar_dispositivo.currentTime = 0;
                  break;
                default:  
              }
            }else{
              var home_juego = GLOBAL.ArrayJuegos.filter(A=>A.url === es_juego[1])[0];
              if(home_juego){
                switch(screen.orientation.type) {
                  case 'portrait-primary':
                    $("#girar-dispositivo").removeClass("hidden");
                    $("#div-contenido").addClass("hidden");
                    //reproducir sonido
                    GLOBAL.audio_girar_dispositivo.play(); 
                    break;
                  case 'landscape-primary':
                    $("#girar-dispositivo").addClass("hidden");
                    GLOBAL.audio_girar_dispositivo.pause();
                    GLOBAL.audio_girar_dispositivo.currentTime = 0;
                    $("#div-contenido").removeClass("hidden");
                    break;
                  case 'landscape-secondary':
                    $("#girar-dispositivo").addClass("hidden");
                    GLOBAL.audio_girar_dispositivo.pause();
                    GLOBAL.audio_girar_dispositivo.currentTime = 0;
                    $("#div-contenido").removeClass("hidden");
                    break;
                  default:  
                }
              }
            }
           
          }
        }
      }else{
        var es_juego = window.location.pathname.split("/");
        //console.error(es_juego);
        //var home_juego = GLOBAL.ArrayJuegos.filter(A=> es_juego[2] && A.url === es_juego[2])[0];
        if(es_juego[2]){
          if(es_juego[2] == "veloci-teclado"){
            $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginTop:"1px", marginBottom:"-10px"});

          }
          else if(es_juego[2] != "leo-primero" && es_juego[2] != "ingreso-texto"){
            switch(screen.orientation.type) {
              case 'portrait-primary':
                $("canvas").css("display","none");
                $("#contenedor-barra").addClass("hidden");
                $("#dropdown-orientaciones").addClass("hidden");
                $("#girar-dispositivo").removeClass("hidden");
                //reproducir sonido
                GLOBAL.audio_girar_dispositivo.play();    
                break;
              case 'landscape-primary':
                $("canvas").css("display","block");
                $("#dropdown-orientaciones").removeClass("hidden");
                $("#contenedor-barra").removeClass("hidden");
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              case 'landscape-secondary':
                $("canvas").css("display","block");
                $("#dropdown-orientaciones").removeClass("hidden");
                $("#contenedor-barra").removeClass("hidden");
                $("#girar-dispositivo").addClass("hidden");
                GLOBAL.audio_girar_dispositivo.pause();
                GLOBAL.audio_girar_dispositivo.currentTime = 0;
                break;
              default:  
            }
          }
        }
      }
    });

  }
  
  Routing:any = async (ruta: string, _showLoading_=false)=>{

    ruta = this.verificarPlataformaActual(ruta);
    
    //console.error(this.router.routerState.snapshot.url);
    $("#bg-modal-cargando").removeClass("bg-cargando-maquina");
    $("#bg-modal-cargando").addClass("bg-cargando-normal");
    
    this.verificarLogoCargando();


    if(this.FuncionLimpiarJuego!==false){
      this.FuncionLimpiarJuego();
      this.FuncionLimpiarJuego = false;
    }

    if(_showLoading_){
      this.showLoading();
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
    if(_showLoading_){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate([ruta]));
      await new Promise(resolve => setTimeout(resolve, 1000));
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginTop:"1px", marginBottom:"-10px"});
      await new Promise(resolve => setTimeout(resolve, 500));
      this.hideLoading();
    }
    else{
      this.hideLoading();
      // console.error(ruta)
      // console.error(this.router);
      if(location.pathname.indexOf(ruta)>=0){
        //console.log("entró")
        setTimeout(()=>location.reload(),200);
      }else{
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate([ruta]));
      }
    }
    
    //VALIDAR RUTA PARA ACCEDER A LOS JUEGOS sopa y popit DESDE LA LANDING A LA MISMA VERSION DE LETRAPPS
    this.verificarIntegracionJuegoEduca();
    this.verificarIntegracionLetrapps();
    //console.error(window.location)
    //if(!this.EstoyEnLetraps && !this.EstoyEnJuegoEduca){
    if(!this.EstoyEnLetraps && !this.EstoyEnJuegoEduca && !this.EstoyEnTabulatest){
      $("#bg-modal-cargando").removeClass("bg-cargando-normal");
      $("#bg-modal-cargando").addClass("bg-cargando-maquina");
    }else{
      $("#bg-modal-cargando").removeClass("bg-cargando-maquina");
      $("#bg-modal-cargando").addClass("bg-cargando-normal");
    }
    
    return true;
  }
  offLoading(tiempo = 2000){
    return new Promise(resolve=>{
      $("body").css("background-color", "#fff8ec");
      setTimeout(()=>{
        $('#modalaso').hide()
        resolve(true);
      },tiempo)
    });
  }
  verificarIntegracionLetrapps(ruta:any=""){
    this.OpcionesIntegracionLetrapps = {};
    let listo_no_mas_for = false;
    if(ruta[0] != "/" && ruta!=""){
      ruta = "/"+ruta;
    }

    this.swal.close();
    if(ruta.indexOf("maquina-de-lecturas")>=0 && this.EstoyEnLetraps){
      ruta = this.TipoIngreso;
    }
    for(let i=0 ; i <___RUTAS_DISPONIBLES___.length; i++){
      if(!listo_no_mas_for){
        if(this.router.routerState.snapshot.url.indexOf(___RUTAS_DISPONIBLES___[i].url) >= 0 ){
          this.EstoyEnLetraps = ___RUTAS_DISPONIBLES___[i].opciones.letrapps;
          EstoyEnLetrapps = './home_letrapps.component.html';
          this.OpcionesIntegracionLetrapps = ___RUTAS_DISPONIBLES___[i].opciones;
          listo_no_mas_for = true;

        }
        else this.EstoyEnLetraps = false;

        if(this.EstoyEnLetraps && ruta.indexOf(___RUTAS_DISPONIBLES___[i].url) === -1){
          ruta = ___RUTAS_DISPONIBLES___[i].url+ruta;
          ruta = ruta.replaceAll("//","/");
        }
      }
    }
    
    return ruta;
  }
  verificarIntegracionJuegoEduca(ruta:any=""){
    isNavidad = false;
    this.OpcionesIntegracionJuegoEduca = {};
    let listo_no_mas_for = false;
    if(ruta[0] != "/" && ruta!=""){
      ruta = "/"+ruta;
    }
    this.swal.close();

    if(ruta.indexOf("maquina-de-lecturas")>=0 && this.EstoyEnJuegoEduca){
      ruta = this.TipoIngreso;
    }
    for(let i=0 ; i <___RUTAS_DISPONIBLES___.length; i++){
      if(!listo_no_mas_for){
        if(this.router.routerState.snapshot.url.indexOf(___RUTAS_DISPONIBLES___[i].url) >= 0 ){
          this.EstoyEnJuegoEduca = ___RUTAS_DISPONIBLES___[i].opciones.juegoeduca;
          //EstoyEnLetrapps = './home_letrapps.component.html';
          this.OpcionesIntegracionJuegoEduca = ___RUTAS_DISPONIBLES___[i].opciones;

          if(this.OpcionesIntegracionJuegoEduca.isNavidad){
            isNavidad = false;
          }
          listo_no_mas_for = true;
          this.PlataformaRutaActual = "juegoeduca";

        }
        else this.EstoyEnJuegoEduca = false;

        if(this.EstoyEnJuegoEduca && ruta.indexOf(___RUTAS_DISPONIBLES___[i].url) === -1){
          ruta = ___RUTAS_DISPONIBLES___[i].url+ruta;
          ruta = ruta.replaceAll("//","/");
        }
      }
    }
    return ruta;
  }
  verificarPlataformaActual(ruta:any=""){
    this.OpcionesPlataformaActual = {};
    let listo_no_mas_for = false;
    if(ruta[0] != "/" && ruta!=""){
      ruta = "/"+ruta;
    }
    this.swal.close();
    for(let i=0 ; i <___RUTAS_DISPONIBLES___.length; i++){
      if(!listo_no_mas_for){
        if(this.router.routerState.snapshot.url.indexOf(___RUTAS_DISPONIBLES___[i].url) >= 0 ){
          this.PlataformaRutaActual = ___RUTAS_DISPONIBLES___[i].opciones.plataforma;
          this.EstoyEnLetraps = ___RUTAS_DISPONIBLES___[i].opciones.letrapps;
          this.OpcionesPlataformaActual = ___RUTAS_DISPONIBLES___[i].opciones;
          listo_no_mas_for = true;

        }
        //else this.EstoyEnLetraps = false;

        if(this.OpcionesPlataformaActual!="juegoeduca" && ruta.indexOf(___RUTAS_DISPONIBLES___[i].url) === -1 && listo_no_mas_for){
          ruta = ___RUTAS_DISPONIBLES___[i].url+ruta;
          ruta = ruta.replaceAll("//","/");
        }
      }
    }
    return ruta;
  }
  cargarAudioFrases(){
    this.arrayAudioFrasesCorrectas = [];
    this.arrayAudioFrasesIncorrectas = [];
    for (let x = 0; x < this.arrayAudioFrasesCorrectasString.length; x++) {
      this.arrayAudioFrasesCorrectas.push(new Audio('/assets/audio/frases/correctas/'+this.arrayAudioFrasesCorrectasString[x]))      
    }
    for (let x = 0; x < this.arrayAudioFrasesIncorrectasString.length; x++) {
      this.arrayAudioFrasesIncorrectas.push(new Audio('/assets/audio/frases/incorrectas/'+this.arrayAudioFrasesIncorrectasString[x]))      
    }
  }
  detenerAudioFrases(){
    for (let x = 0; x < this.arrayAudioFrasesCorrectas.length; x++) {
      this.arrayAudioFrasesCorrectas[x].pause();
    }
    for (let x = 0; x < this.arrayAudioFrasesIncorrectas.length; x++) {
      this.arrayAudioFrasesIncorrectas[x].pause();
    }
  }
  getLeoPrimero(){
    
    // $('body').loadingModal({
    //    position:'auto',
    //    text:'Cargando...',
    //    color:'#37395b',
    //    opacity:'1',
    //    backgroundColor:'#fdefd3',
    //    animation:'doubleBounce'
    //  });

    //this.showLoading();
    return new Promise((resolve)=>{
      this.http.get<any>('https://api.gateway.integritic.cl/v1/leoprimero/leoprimero').subscribe(data => {
        this.LeoPrimero = data;
        this.CursosLeoPrimero = [];
        for(let i=0; i < data.length; i++){
          for(let x=0; x < data[i].CURSO.length; x++){
            this.CursosLeoPrimero.push(data[i].CURSO[x]);
          }
        }
        this.CursosLeoPrimero = this.CursosLeoPrimero.filter(this.uniqueGlobal);
        this.CursosLeoPrimero = this.CursosLeoPrimero.sort();
        let NT2 = this.CursosLeoPrimero.pop();
        let NT1 = this.CursosLeoPrimero.pop();
        this.CursosLeoPrimero = this.CursosLeoPrimero.reverse();
        this.CursosLeoPrimero.push(NT2);
        this.CursosLeoPrimero.push(NT1);
        this.CursosLeoPrimero = this.CursosLeoPrimero.reverse();
        this.hideLoading();
        //setTimeout(()=>$('body').loadingModal('destroy'),1000);

        resolve(true);
      });
      
    });
    
  }
  activarPuertas(){
    this.abrirPuertas();
    setTimeout(()=>$("#contenedor-puertas").css({display:"inline-flex"}),200);
  }
  ocultarPuertas(){
    $("#contenedor-puertas").css({display:"none"});
    //this.abrirPuertas()
  }
  cerrarPuertas(){
    $("#puerta-izq").css({left:  (window.outerWidth/2) - 960 })
    $("#puerta-der").css({left: window.outerWidth/2  })
    // var audio__ = new Audio('/assets/audio/SCI-FI-DOOR 2_mas corto.mp3');
    // audio__.play();
  }
  abrirPuertas(){
    $("#puerta-izq").css({left:- 960 })
    $("#puerta-der").css({left: window.outerWidth })

    setTimeout(()=>{
      this.ocultarPuertas();
    },5000);
  }
  getTomosLeoPrimero(Curso){
    let data = this.LeoPrimero;
    let aux = [];
    let tomos = [];
    for(let i=0; i < data.length; i++){
      let la_encontro = false;
      for(let x=0; x < data[i].CURSO.length; x++){
         if(data[i].CURSO[x]===Curso){
           la_encontro = true;
         }
      }
      if(la_encontro){
        if(data[i].TOMO != "")
          tomos.push(Number(data[i].TOMO));
      }
    }
    this.CursosFiltro1 = Curso;
    this.TomoLeoPrimero = tomos.filter(this.uniqueGlobal);
    this.TomoLeoPrimero = this.TomoLeoPrimero.sort();
  }
  correccionFuenteLigada(string){
    var MAYUSCULAS = ["B","D","J","O","P"];
    var MINUSCULAS = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","ñ","o","p","q","r","s","t","u","v","w","x","y","z"];

    var primera_letra = string.charAt(0);
    var segunda_letra = string.charAt(1);

    if(primera_letra === primera_letra.toUpperCase()){ //PRIMERA LETRA ES MAYUSCULA
        var filter_letra = MAYUSCULAS.filter(A=>A === primera_letra)[0];
        if(filter_letra){
            if(segunda_letra === segunda_letra.toUpperCase()){
                return string.replace(primera_letra,primera_letra);
            }else{
                return string.replace(primera_letra,primera_letra+"_");
            }
        }else{
            return string;
        }
    }else{
        //MINUSCULA
        return string.replace(primera_letra,"_"+primera_letra);
    }
  }
  getClaseLeoPrimero(Tomo){
    let data = this.LeoPrimero;
    let aux = [];
    let clases = [];
    for(let i=0; i < data.length; i++){
      let la_encontro = false;
      for(let x=0; x < data[i].CURSO.length; x++){
         if(data[i].CURSO[x]===this.CursosFiltro1){
           la_encontro = true;
         }
      }
      if(la_encontro){
        aux.push(data[i]);
        if(Number(data[i].TOMO) === Number(Tomo)){
          for(let x=0; x < data[i].CLASE.length; x++){
            clases.push(parseInt(data[i].CLASE[x]));
          }
        }
      }
    }
    this.CursosFiltro2=Tomo;
    this.ClaseLeoPrimero = clases.filter(this.uniqueGlobal);
    this.ClaseLeoPrimero = this.ClaseLeoPrimero.sort((a,b)=>a-b);
  }
  CargarClase(Clase){
    let data = this.LeoPrimero;
    let aux = [];
    let lecturas = [];
    for(let i=0; i < data.length; i++){
      let la_encontro = false;
      for(let x=0; x < data[i].CURSO.length; x++){
         if(data[i].CURSO[x]===this.CursosFiltro1){
           la_encontro = true;
         }
      }
      if(la_encontro){
        aux.push(data[i]);
        if(Number(data[i].TOMO) === Number(this.CursosFiltro2)){
          for(let x=0; x < data[i].CLASE.length; x++){
            if(Number(data[i].CLASE[x]) === Number(Clase)){
              lecturas.push(data[i]);
            }
          }
        }
      }
    }
    this.CursosFiltro3=Clase;
    this.LecturasLeoPrimero = lecturas.filter(this.uniqueGlobal);
    this.LecturasLeoPrimero = this.LecturasLeoPrimero.sort();
  }
  CargarLectura(Lectura){
    this.CursosFiltro4 = Lectura;
  }
  cargarTextoLeoPrimero(){
    if(this.CursosFiltro4===undefined){
      alert("Filtro incorrecto."); return false;
    }
    let Lectura = this.LeoPrimero.filter(A=>A._id === this.CursosFiltro4)[0];
    return Lectura;
  }
  async showLoading(time=20){

    $('#modalaso').show();
    $('#modalaso').addClass('jquery-loading-modal--visible').removeClass('jquery-loading-modal--hidden');
     /*let contador=0;
      let interval = setInterval(()=>{
         BarraProgreso.set(++contador);
         if(contador===100) clearInterval(interval);
      },time)*/
  }
  async hideLoading(){
    
    $('#modalaso').removeClass('jquery-loading-modal--visible').addClass('jquery-loading-modal--hidden');
    //await new Promise(resolve => setTimeout(resolve, 1000));
    $("#modalaso").hide();
  }
  getRouting(){
    return this.router;
  }
  Texto_Actual:any = {};
  _Texto_Actual_ = {
    cantidad_de_palabras_reales_para_contar: 0,
    modo_palabras: false,
    palabras_clave_silabas_string: [],
    todas_las_palabras_string: [],
    palabras_clave_string: [],
    palabras_filtradas: [],
    texto_completo: "",
    titulo: ""
  };
  Reemplazo_de_palabras = {
    "ADJ" : "Adjetivo",
    "ADP" : "Adposición",
    "ADV" : "Adverbio",
    "AUX" : "Auxiliar",
    "CONJ": "Conjunción",
    "CCONJ" : "Conjunción Coordinada",
    "DET" : "Determinante",
    "INTJ" : "Interjección",
    "NOUN": "Sustantivo",
    "NUM" : "Numeral",
    "O" : "Otro",
    "PART" : "Partícula",
    "PRON" : "Pronombre",
    "PROPN": "Sustantivo Propio",
    "PUNCT" : "Puntuación",
    "SCONJ" : "Conjunción Subordinante",
    "SYM" : "Símbolo",
    "VERB" : "Verbo"
  };
  resetDificultades(){
    for(let i in localStorage ){
      if(i.indexOf("Nivel-Dificultad")>=0){
        localStorage.removeItem(i);
      }
    }
  }
  getTextoActual(){
    this.Texto_Actual = localStorage.getItem("Texto_Actual")==null?false:JSON.parse(localStorage.getItem("Texto_Actual"));
    if(this.Texto_Actual === false){
      this.Texto_Actual = this._Texto_Actual_;
    }
    return this.Texto_Actual;
  }
  uniqueGlobal(value, index, self) {
    return self.indexOf(value) === index;
  };
  setTextoActual(obj){
    this.Texto_Actual = obj;
    localStorage.setItem("Texto_Actual", JSON.stringify(this.Texto_Actual));
    return this.Texto_Actual;
  }
  setTipoIngreso(txt){
    this.TipoIngreso = txt;
    localStorage.setItem("TipoIngreso", this.TipoIngreso);
    return this.TipoIngreso;
  }
  getTipoIngreso(){
    this.TipoIngreso = localStorage.getItem("TipoIngreso")==null?false:localStorage.getItem("TipoIngreso");
    return this.TipoIngreso;
  }
  //FUENTE PDF ACTIVIDADES
  setTipoFuentePDF(txt){
    this.TipoFuentePDF = txt;
    localStorage.setItem("TipoFuentePDF", this.TipoFuentePDF);
    return this.TipoFuentePDF;
  }
  getTipoFuentePDF(){
    this.TipoFuentePDF = localStorage.getItem("TipoFuentePDF")==null?false:localStorage.getItem("TipoFuentePDF");
    return this.TipoFuentePDF;
  }
  getConfiguracionTemporalJuego(){
    this.ConfiguracionTemporalJuego = localStorage.getItem("ConfiguracionTemporalJuego")==null?false:JSON.parse(localStorage.getItem("ConfiguracionTemporalJuego"));
    return this.ConfiguracionTemporalJuego;
  }
  setConfiguracionTemporalJuego(obj){
    this.ConfiguracionTemporalJuego = obj;
    localStorage.setItem("ConfiguracionTemporalJuego", JSON.stringify(this.ConfiguracionTemporalJuego));
    return this.ConfiguracionTemporalJuego;
  }
  setNivelJuego(Nivel,Juego){
    localStorage.setItem("Nivel-Dificultad-"+Juego, Nivel);
    return Nivel;
  }
  setPPMJuego(Nivel,Juego){
    localStorage.setItem("PPM-"+Juego, Nivel);
    return Nivel;
  }
  getPPMJuego(Juego){
    return localStorage.getItem("PPM-"+Juego);
  }
  getCursoJuego(Juego){
    return localStorage.getItem("curso-"+Juego);
  }
  setCursoJuego(Nivel,Juego){
    localStorage.setItem("curso-"+Juego, Nivel);
    return Nivel;
  }
  setNivelesJugados(Nivel,Juego){
    localStorage.setItem("Niveles-Jugados-"+Juego, Nivel);
    return Nivel;
  }
  getNivelesJugados(Juego){
    return localStorage.getItem("Niveles-Jugados-"+Juego);
  }
  getNivelJuego(Juego){
    return localStorage.getItem("Nivel-Dificultad-"+Juego);
  }
  setConfiguracionJuegoActual(Conf,Juego){
    localStorage.setItem("Configuracion-Actual-"+Juego, JSON.stringify(Conf));
    return Conf;
  }
  getConfiguracionJuegoActual(Juego){
    var Conf = localStorage.getItem("Configuracion-Actual-"+Juego)==null?null:JSON.parse(localStorage.getItem("Configuracion-Actual-"+Juego));
    return Conf;
  }
  async comprobarTexto(titulo, texto){
    let self = this;
    var _data_:any = {
      KeyPhrases: [],
      SyntaxTokens: []
    };
    return new Promise(async resolve=>{
      var params = {
        LanguageCode: "es",
        Text:  texto
      };
      let palabras_originales = [];
      let palabras_clave_string = [];
      let palabras_clave_silabas_string = [];
      let todas_las_palabras_string = [];
      let sustantivos_array = [];
      let sustantivos_array_object = [];
      let palabras_claves:any = await new Promise(resolve2=>{
        this.api.analizeComprehend(texto).then((data:any)=>{
        let err:any = false;
        _data_ = data
          if (err) console.log(err, err.stack); // an error occurred
          else{
            if(data.KeyPhrases){
              let data_aux = data.KeyPhrases;
              let return_data = [];
              let search_comprueba = {};
              for(let i=0; i < data_aux.length; i++){
                let data_for_aux = data_aux[i];
                data_for_aux.Text = data_for_aux.Text.replaceAll("/[^a-zA-Z]/g","");
                data_for_aux.Text = data_for_aux.Text.replaceAll(/[-–&/\#,+()$~%.'":*?¡¿!.—<>{}]/g, '');
                data_for_aux.Text = data_for_aux.Text.replaceAll("...","");
                data_for_aux.Text = data_for_aux.Text.replaceAll("...","");
                data_for_aux.Text = data_for_aux.Text.replaceAll("…","");
                data_for_aux.Text = data_for_aux.Text.capitalize();
                let prueba_aux = data_for_aux.Text.split(" ");
                if(prueba_aux.length===1){
                  prueba_aux = data_for_aux.Text.split("\n");
                }
                if(prueba_aux.length===1 && !search_comprueba[data_for_aux.Text]){
                  if(prueba_aux[0].length>1){
                    data_for_aux.silabas = silabaJS.getSilabas(data_for_aux.Text);
                    data_for_aux.selecionada=true;
                    return_data.push(data_for_aux);
                    palabras_clave_silabas_string.push(data_for_aux.Text+" ("+data_for_aux.silabas.silabas_string.join(", ")+")");
                    palabras_clave_string.push(data_for_aux.Text);
                    search_comprueba[data_for_aux.Text] = true;
                  }
                }
              }
              resolve2(return_data);
              return;
            }
            resolve2([]);
            return;
          }
        });
      });

      let palabras = await new Promise(resolve2=>{
        let data = _data_;
        if(data.SyntaxTokens){
          let data_aux = data.SyntaxTokens;
          let return_data = [];
          let data_aux2 = JSON.parse(JSON.stringify(data_aux));
          for(let i=0; i < data_aux2.length; i++){
            let data_for_aux = data_aux2[i];
            data_for_aux.PartOfSpeech.Tag = self.Reemplazo_de_palabras[data_for_aux.PartOfSpeech.Tag];
            data_for_aux.silabas = silabaJS.getSilabas(data_for_aux.Text);
            palabras_originales.push(data_for_aux);
          }
          for(let i=0; i < data_aux.length; i++){
            let data_for_aux = data_aux[i];
            data_for_aux.PartOfSpeech.Tag = self.Reemplazo_de_palabras[data_for_aux.PartOfSpeech.Tag];
            data_for_aux.Text = data_for_aux.Text.replaceAll("/[^a-zA-Z ]/g","");
            data_for_aux.Text = data_for_aux.Text.replaceAll(/[-–&/\#,+()$~%.'":*?¡¿!.—<>{}]/g, '');
            data_for_aux.Text = data_for_aux.Text.replaceAll("...","");
            data_for_aux.Text = data_for_aux.Text.replaceAll("…","");
            data_for_aux.Text = data_for_aux.Text.capitalize();

            let prueba_de_espacios = data_for_aux.Text.split(" ");
            if(data_for_aux.PartOfSpeech.Tag != "Otro" && data_for_aux.PartOfSpeech.Tag != "Puntuación" && data_for_aux.PartOfSpeech.Tag != "Símbolo" ){
              data_for_aux.TokenId = return_data.length+1;
              data_for_aux.silabas = silabaJS.getSilabas(data_for_aux.Text);
              if(data_for_aux.PartOfSpeech.Tag === "Sustantivo"){
                data_for_aux.selecionada = true;
                sustantivos_array.push(data_for_aux.Text);
                sustantivos_array_object.push(data_for_aux);
              }
              return_data.push(data_for_aux);
              todas_las_palabras_string.push(data_for_aux.Text)
            }
          }
          resolve2(return_data);
          return;
        }
        resolve2([]);
        return;
      });
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      };
      let mezcla:any = palabras_clave_string.concat(sustantivos_array);
      mezcla = mezcla.filter(onlyUnique);
      let data = {
        titulo:titulo,
        texto_completo: texto,
        palabras_claves: palabras_claves, 
        palabras_claves_2: palabras_clave_string, 
        sustantivos_array:sustantivos_array,
        sustantivos_array_object:sustantivos_array_object,
        palabras_filtradas: palabras,
        todas_las_palabras: palabras_originales,
        palabras_clave_string:mezcla,
        todas_las_palabras_string:todas_las_palabras_string,
        palabras_clave_silabas_string: palabras_clave_silabas_string,
        cantidad_de_palabras_reales_para_contar: palabras_originales.length,
        total_de_palabras_del_texto_sin_contar_caracteres: todas_las_palabras_string.length,
        total_de_palabras_claves: palabras_claves.length
      };
      resolve(self.setTextoActual(data));
    });
  }

  //COOKIE CAMPOS DE SELECCION DE LEO PRIMERO
  setCookieCursoLeoPrimero(txt){
    this.CookieCursoLeoPrimero = txt;
    localStorage.setItem("CookieCursoLeoPrimero", this.CookieCursoLeoPrimero);
    return this.CookieCursoLeoPrimero;
  }
  getCookieCursoLeoPrimero(){
    this.CookieCursoLeoPrimero = localStorage.getItem("CookieCursoLeoPrimero")==null?false:localStorage.getItem("CookieCursoLeoPrimero");
    return this.CookieCursoLeoPrimero;
  }
  setCookieTomoLeoPrimero(txt){
    this.CookieTomoLeoPrimero = txt;
    localStorage.setItem("CookieTomoLeoPrimero", this.CookieTomoLeoPrimero);
    return this.CookieTomoLeoPrimero;
  }
  getCookieTomoLeoPrimero(){
    this.CookieTomoLeoPrimero = localStorage.getItem("CookieTomoLeoPrimero")==null?false:localStorage.getItem("CookieTomoLeoPrimero");
    return this.CookieTomoLeoPrimero;
  }
  setCookieClaseLeoPrimero(txt){
    this.CookieClaseLeoPrimero = txt;
    localStorage.setItem("CookieClaseLeoPrimero", this.CookieClaseLeoPrimero);
    return this.CookieClaseLeoPrimero;
  }
  getCookieClaseLeoPrimero(){
    this.CookieClaseLeoPrimero = localStorage.getItem("CookieClaseLeoPrimero")==null?false:localStorage.getItem("CookieClaseLeoPrimero");
    return this.CookieClaseLeoPrimero;
  }
  setCookieLecturaLeoPrimero(txt){
    this.CookieLecturaLeoPrimero = txt;
    localStorage.setItem("CookieLecturaLeoPrimero", this.CookieLecturaLeoPrimero);
    return this.CookieLecturaLeoPrimero;
  }
  getCookieLecturaLeoPrimero(){
    this.CookieLecturaLeoPrimero = localStorage.getItem("CookieLecturaLeoPrimero")==null?false:localStorage.getItem("CookieLecturaLeoPrimero");
    return this.CookieLecturaLeoPrimero;
  }
  setCookieTipoTextoPalabras(txt){
    this.CookieTipoTextoPalabras = txt;
    localStorage.setItem("CookieTipoTextoPalabras", this.CookieTipoTextoPalabras);
    return this.CookieTipoTextoPalabras;
  }
  getCookieTipoTextoPalabras(){
    this.CookieTipoTextoPalabras = localStorage.getItem("CookieTipoTextoPalabras")==null?false:localStorage.getItem("CookieTipoTextoPalabras");
    if(this.CookieTipoTextoPalabras === false){
      this.CookieTipoTextoPalabras = "TEXTO";
    }
    return this.CookieTipoTextoPalabras;
  }
  setCookieAudioInstruccion(txt){
    this.CookieAudioInstruccion = txt;
    localStorage.setItem("CookieAudioInstruccion", this.CookieAudioInstruccion);
    return this.CookieAudioInstruccion;
  }
  getCookieAudioInstruccion(){
    this.CookieAudioInstruccion = localStorage.getItem("CookieAudioInstruccion")==null?false:localStorage.getItem("CookieAudioInstruccion");
    if(this.CookieAudioInstruccion === false){
      this.CookieAudioInstruccion = "true";
    }
    return this.CookieAudioInstruccion;
  }
  getLocalStorage(variable,defecto){
    this[variable] = localStorage.getItem(variable)==null?defecto:localStorage.getItem(variable);
    if(this[variable] === false){
      this[variable] = "false";
    }
    return this[variable];
  }
  setLocalStorage(variable,txt){
    this[variable] = txt;
    localStorage.setItem(variable, this[variable]);
    return this[variable];
  }

  setInstruccionAudio(Bool,Juego){
    localStorage.setItem("InstruccionAudio-"+Juego, Bool);
    return Bool;
  }
  getInstruccionAudio(Juego){
    return localStorage.getItem("InstruccionAudio-"+Juego);
  }

  //KPI
  async saveKPI(ACCION,PLATAFORMA,TEXTO,TIPO,JUEGO_ACTIVIDAD){
    this._KPI_.PLATAFORMA = PLATAFORMA;
    this._KPI_.TIPO = TIPO;
    this._KPI_.TEXTO = TEXTO;
    this._KPI_.JUEGO_ACTIVIDAD = JUEGO_ACTIVIDAD;
    this._KPI_.IP = await this.getIpUsuario();
    this._KPI_._id = this._ID_PESTANIA_;

    this._KPI_.ACCIONES.push({
      ACCION: ACCION, 
      FECHAHORA: new Date().toLocaleString()
    });

    firebase.database().ref('KPI/JUEGOS_ACTIVIDADES_MAQUINA_LECTURA/' + this._ID_PESTANIA_).set(this._KPI_);
    
    console.error("guarda kpi");
  }

  getIpUsuario(){
    return new Promise(resolve=>{
      //this.http.get<any>('https://letrapps.integritic.cl/api/GET/GET_IP_USUARIO').subscribe(DATA => {
      this.http.get<any>('https://letrapps.cl/api/GET/GET_IP_USUARIO').subscribe(DATA => {
        //console.error(DATA);
        resolve(DATA);
      });
    });
  }

  verificarLogoCargando(){
    if(this.EstoyEnLetraps){
      $("#cargando_JE").addClass("hidden");
      $("#cargando_LT").removeClass("hidden");
    }else{
      if(this.ActividadesEnLetrapps){
        $("#cargando_JE").addClass("hidden");
        $("#cargando_LT").addClass("hidden");
        $("#cargando_TBT").addClass("hidden");
        if(this.EstoyEnTabulatest){
          $("#cargando_TBT").removeClass("hidden");
          $("#cargando_LT").addClass("hidden");
        }else{
          $("#cargando_LT").removeClass("hidden");
        }
      }else{
        $("#cargando_LT").addClass("hidden");
        $("#cargando_TBT").addClass("hidden");
        $("#cargando_JE").removeClass("hidden");
      }
    }
  }
  removerDuplicadosArrayObject(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};
    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
  }
  showModal(msg){$('body').loadingModal({text: msg, color:'#fff'});$('body').loadingModal('animation', 'wave').loadingModal('backgroundColor', 'black');}
  hideModal(){$('body').loadingModal('hide');$('body').loadingModal('destroy')}

  async getAllCategoriasPalabras(){
    return new Promise((resolve)=>{
        console.log(this.getDBName())
        this.http.get<any>('https://api.gateway.integritic.cl/juegoeduca/getCategoriasJE?filter='+JSON.stringify({"DB_NAME":this.getDBName()})).subscribe(DATA => {
        setTimeout(() => {
          //console.error(DATA);
          //if(DATA)DATA = DATA.sort((a, b) => (a.PALABRA > b.PALABRA) ? 1 : -1)
          var result = [];
          for(let x=0;x<DATA.length;x++){

            if(DATA[x].JUEGOS_ASOCIADOS){

              if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="JET LECTOR").length>0 && (this.OpcionesPlataformaActual.juego == "jet-lector") ){ //PARA MOSTRAR CATEGORIAS EN JET-LECTOR
                DATA[x].HABILITADA = "true";
                DATA[x].OCULTAR = true;
              }
              if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="NAVE INTERLECTORA").length>0 && (this.OpcionesPlataformaActual.juego == "nave-interlectora") ){ //PARA MOSTRAR CATEGORIAS EN JET-LECTOR
                DATA[x].HABILITADA = "true";
                DATA[x].OCULTAR = true;
              }
              if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="VELOCITECLADO").length>0 && (this.OpcionesPlataformaActual.juego == "veloci-teclado") ){ //PARA MOSTRAR CATEGORIAS EN VELOCITECLADO
                DATA[x].HABILITADA = "true";
                DATA[x].OCULTAR = true;
              }
              if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="GOLPEA-AL-VIRUS").length>0 && (this.OpcionesPlataformaActual.juego == "golpea-al-virus") ){ //PARA MOSTRAR CATEGORIAS EN VELOCITECLADO
                DATA[x].HABILITADA = "true";
                DATA[x].OCULTAR = true;
              }
            }

            if(DATA[x].HABILITADA){
              if(DATA[x].HABILITADA === "true"){
                result.push(DATA[x]);
              }
            }else{
              result.push(DATA[x]);
            }
          }
          resolve(result);
          //this.global.hideLoading();
        }, 200);
      });
    });
  }
  getDatosUsuario(){
    this.ID_USUARIO = localStorage.getItem("ID_USUARIO")==null?false:JSON.parse(localStorage.getItem("ID_USUARIO"));
    this.ID_INSTITUCION = localStorage.getItem("ID_INSTITUCION")==null?false:JSON.parse(localStorage.getItem("ID_INSTITUCION"));
    this.PROMOCION_ACTUAL = localStorage.getItem("PROMOCION_ACTUAL")==null?false:JSON.parse(localStorage.getItem("PROMOCION_ACTUAL"));
    return [this.ID_USUARIO,this.ID_INSTITUCION,this.PROMOCION_ACTUAL];
  }
  setDatosUsuario(ID_USUARIO,ID_INSTITUCION,PROMOCION_ACTUAL){
    this.ID_USUARIO = ID_USUARIO;
    this.ID_INSTITUCION = ID_INSTITUCION;
    this.PROMOCION_ACTUAL = PROMOCION_ACTUAL;
    localStorage.setItem("ID_USUARIO", ID_USUARIO);
    localStorage.setItem("ID_INSTITUCION", ID_INSTITUCION);
    localStorage.setItem("PROMOCION_ACTUAL", PROMOCION_ACTUAL);
    return [this.ID_USUARIO,this.ID_INSTITUCION,this.PROMOCION_ACTUAL];
  }
  async getCategoriasConPalabras(){
    var palabras_categorias;
    var BD_CATEGORIAS_PALABRAS;
    BD_CATEGORIAS_PALABRAS = await this.getAllCategoriasPalabras();
    BD_CATEGORIAS_PALABRAS = BD_CATEGORIAS_PALABRAS.filter(A=>/*A.VIDEO_LSCH != "true"*/A.ESTADO_CATEGORIA_PALABRA != "false");
    //console.error(BD_CATEGORIAS_PALABRAS);
    //palabras_categorias = await this.getPalabrasFrecuentes();
    var categorias = [];


    for(let x=0;x<BD_CATEGORIAS_PALABRAS.length;x++){
      if(BD_CATEGORIAS_PALABRAS[x].id_categoria != "NO_CATEGORIZADA"){
        //var _palabras_ = palabras_categorias.filter(PAL => PAL.CATEGORIA.some(item => item === BD_CATEGORIAS_PALABRAS[x].id_categoria));
        //if(_palabras_.length>0){
          var imagen = BD_CATEGORIAS_PALABRAS[x].IMAGEN?BD_CATEGORIAS_PALABRAS[x].IMAGEN:"";//"assets/disenios/sin_imagen.png";
          categorias.push({
            id : BD_CATEGORIAS_PALABRAS[x].id_categoria, 
            nombre : BD_CATEGORIAS_PALABRAS[x].NOMBRE, 
            ocultar : BD_CATEGORIAS_PALABRAS[x].OCULTAR?BD_CATEGORIAS_PALABRAS[x].OCULTAR:false, 
            orden: Number(BD_CATEGORIAS_PALABRAS[x].ORDEN), 
            src: imagen,
            nombre_propio: BD_CATEGORIAS_PALABRAS[x].NOMBRES_PROPIOS_CATEGORIA?BD_CATEGORIAS_PALABRAS[x].NOMBRES_PROPIOS_CATEGORIA:"false", 
            ingles:BD_CATEGORIAS_PALABRAS[x].INGLES_CATEGORIA_PALABRAS?BD_CATEGORIAS_PALABRAS[x].INGLES_CATEGORIA_PALABRAS:"false",
            palabras_grandes:BD_CATEGORIAS_PALABRAS[x].PALABRAS_GRANDES_CATEGORIA?BD_CATEGORIAS_PALABRAS[x].PALABRAS_GRANDES_CATEGORIA:"false"
          });
        //}
      }
      
    }

    var _CATEGORIAS_ = [];
    _CATEGORIAS_ = this.removerDuplicadosArrayObject(categorias,"id");
    _CATEGORIAS_ = _CATEGORIAS_.sort((a, b) => (a.orden > b.orden) ? 1 : -1)

    /*
    for(let x=0;x<_CATEGORIAS_.length;x++){
      var cant_palabras = palabras_categorias.filter(PAL => PAL.CATEGORIA.some(item => item === _CATEGORIAS_[x].id));
      //console.error(cant_palabras);
      _CATEGORIAS_[x].CANT_PALABRAS = cant_palabras.length;
      _CATEGORIAS_[x].PALABRAS = cant_palabras;
    }
    
    */
    return _CATEGORIAS_;
  }
  async getAllCategoriasPalabrasMetodoGlobal(){
    return new Promise((resolve)=>{
        // console.log(this.getDBName())
        this.http.get<any>('https://api.gateway.integritic.cl/juegoeduca/getCategoriasJE?filter='+JSON.stringify({"DB_NAME":this.getDBName()})).subscribe(DATA => {
        setTimeout(() => {
          DATA = DATA.filter(A => A.HABILITADA_MG == "true");
          //console.error(DATA);
          //if(DATA)DATA = DATA.sort((a, b) => (a.PALABRA > b.PALABRA) ? 1 : -1)
          // var result = [];
          // for(let x=0;x<DATA.length;x++){

          //   if(DATA[x].JUEGOS_ASOCIADOS){

          //     if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="JET LECTOR").length>0 && (this.OpcionesPlataformaActual.juego == "jet-lector") ){ //PARA MOSTRAR CATEGORIAS EN JET-LECTOR
          //       DATA[x].HABILITADA = "true";
          //       DATA[x].OCULTAR = true;
          //     }
          //     if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="NAVE INTERLECTORA").length>0 && (this.OpcionesPlataformaActual.juego == "nave-interlectora") ){ //PARA MOSTRAR CATEGORIAS EN JET-LECTOR
          //       DATA[x].HABILITADA = "true";
          //       DATA[x].OCULTAR = true;
          //     }
          //     if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="VELOCITECLADO").length>0 && (this.OpcionesPlataformaActual.juego == "veloci-teclado") ){ //PARA MOSTRAR CATEGORIAS EN VELOCITECLADO
          //       DATA[x].HABILITADA = "true";
          //       DATA[x].OCULTAR = true;
          //     }
          //     if(DATA[x].JUEGOS_ASOCIADOS.filter(A=>A==="GOLPEA-AL-VIRUS").length>0 && (this.OpcionesPlataformaActual.juego == "golpea-al-virus") ){ //PARA MOSTRAR CATEGORIAS EN VELOCITECLADO
          //       DATA[x].HABILITADA = "true";
          //       DATA[x].OCULTAR = true;
          //     }
          //   }

          //   if(DATA[x].HABILITADA){
          //     if(DATA[x].HABILITADA === "true"){
          //       result.push(DATA[x]);
          //     }
          //   }else{
          //     result.push(DATA[x]);
          //   }
          // }
          resolve(DATA);
          //this.global.hideLoading();
        }, 200);
      });
    });
  }
  async getCategoriasConPalabrasMetodoGlobal(){
    var palabras_categorias;
    var BD_CATEGORIAS_PALABRAS;
    BD_CATEGORIAS_PALABRAS = await this.getAllCategoriasPalabrasMetodoGlobal();
    BD_CATEGORIAS_PALABRAS = BD_CATEGORIAS_PALABRAS.filter(A=>A.HABILITADA_MG != "false");
    var categorias = [];
    for(let x=0;x<BD_CATEGORIAS_PALABRAS.length;x++){
      if(BD_CATEGORIAS_PALABRAS[x].id_categoria != "NO_CATEGORIZADA"){
          var imagen = BD_CATEGORIAS_PALABRAS[x].IMAGEN?BD_CATEGORIAS_PALABRAS[x].IMAGEN:"";//"assets/disenios/sin_imagen.png";
          categorias.push({
            id : BD_CATEGORIAS_PALABRAS[x].id_categoria, 
            nombre : BD_CATEGORIAS_PALABRAS[x].NOMBRE, 
            ocultar : BD_CATEGORIAS_PALABRAS[x].OCULTAR?BD_CATEGORIAS_PALABRAS[x].OCULTAR:false, 
            orden: Number(BD_CATEGORIAS_PALABRAS[x].ORDEN), 
            src: imagen,
            nombre_propio: BD_CATEGORIAS_PALABRAS[x].NOMBRES_PROPIOS_CATEGORIA?BD_CATEGORIAS_PALABRAS[x].NOMBRES_PROPIOS_CATEGORIA:"false", 
            ingles:BD_CATEGORIAS_PALABRAS[x].INGLES_CATEGORIA_PALABRAS?BD_CATEGORIAS_PALABRAS[x].INGLES_CATEGORIA_PALABRAS:"false",
            palabras_grandes:BD_CATEGORIAS_PALABRAS[x].PALABRAS_GRANDES_CATEGORIA?BD_CATEGORIAS_PALABRAS[x].PALABRAS_GRANDES_CATEGORIA:"false"
          });
      }
      
    }

    var _CATEGORIAS_ = [];
    _CATEGORIAS_ = this.removerDuplicadosArrayObject(categorias,"id");
    _CATEGORIAS_ = _CATEGORIAS_.sort((a, b) => (a.orden > b.orden) ? 1 : -1)

    /*
    for(let x=0;x<_CATEGORIAS_.length;x++){
      var cant_palabras = palabras_categorias.filter(PAL => PAL.CATEGORIA.some(item => item === _CATEGORIAS_[x].id));
      //console.error(cant_palabras);
      _CATEGORIAS_[x].CANT_PALABRAS = cant_palabras.length;
      _CATEGORIAS_[x].PALABRAS = cant_palabras;
    }
    
    */
    return _CATEGORIAS_;
  }
  getPalabrasFrecuentes(){
    //MOSTRAR CATEGORÍAS SEMÁNTICAS SÓLO LA PALABRAS CON EL MÉTODO SELECCIONADO
    return new Promise((resolve)=>{
      var FILTROS = {};
      FILTROS["DB_NAME"] = this.getDBName();;
      FILTROS["FRECUENTE"] = "true";
      FILTROS["LETRA_INICIAL"] = ["a","á","b","c","ch","d","e","é","f","g","h","i","í","j","k","l","ll","m","n","ñ","o","ó","p","q","r","s","t","u","ú","v","w","x","y","z"];
      FILTROS["CANTIDAD_PALABRAS"] = 10000;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Access-Control-Allow-Origin':'*'

        })
      };

      //this.http.get<any>(this.base_url+'/getPalabrasFiltradas/'+JSON.stringify(FILTROS)).subscribe(DATA => {
      //this.http.post<any>("https://api.gateway.integritic.cl/juegoeduca/getPalabrasJE", FILTROS, httpOptions).subscribe(DATA => {
      this.http.get<any>('https://api.gateway.integritic.cl/juegoeduca/getPalabrasJE?filter='+JSON.stringify(FILTROS)).subscribe(DATA => {
        setTimeout(() => {
          console.error(DATA)
          resolve(DATA);
        }, 200);
      });
    });
  }

  getPalabrasFrecuentesJuegoLetras(cat){
    return new Promise((resolve)=>{
      var FILTROS = {};
      FILTROS["DB_NAME"] = this.getDBName();
      FILTROS["FRECUENTE"] = "true";
      FILTROS["LETRA_INICIAL"] = ["a","á","b","c","ch","d","e","é","f","g","h","i","í","j","k","l","ll","m","n","ñ","o","ó","p","q","r","s","t","u","ú","v","w","x","y","z"];
      
      if(cat.length > 0){
        FILTROS["CATEGORIA"] = cat;
      }
      
      FILTROS["CANTIDAD_PALABRAS"] = 10000;

      //this.http.get<any>('https://letrapps.integritic.cl/getPalabrasFiltradas/'+JSON.stringify(FILTROS)).subscribe(DATA => {
      //this.http.get<any>(this.base_url+'/getPalabrasFiltradas/'+JSON.stringify(FILTROS)).subscribe(DATA => {
      this.http.get<any>('https://api.gateway.integritic.cl/juegoeduca/getPalabrasJE?filter='+JSON.stringify(FILTROS)).subscribe(DATA => { 
        setTimeout(() => {
          resolve(DATA);
        }, 200);
      });
    });
  }
  
  getCategoriasByJuegos(JUEGOS){
    return new Promise((resolve)=>{
      var FILTROS = {};
      if(JUEGOS.length > 0){
        FILTROS["JUEGOS_ASOCIADOS"] = JUEGOS;
      }
      this.http.get<any>('https://letrapps.cl/getCategoriasPalabraByJuegos/'+JSON.stringify(FILTROS)).subscribe(DATA => {
        setTimeout(() => {
          resolve(DATA);
        }, 200);
      });
    });
  }

  getAudioSilaba(silaba){
    //MOSTRAR CATEGORÍAS SEMÁNTICAS SÓLO LA PALABRAS CON EL MÉTODO SELECCIONADO
    return new Promise((resolve)=>{
      //this.http.get<any>('https://letrapps.integritic.cl/getAudioSilaba/'+silaba).subscribe(DATA => {
      this.http.get<any>('https://letrapps.cl/getAudioSilaba/'+silaba).subscribe(DATA => {
        setTimeout(() => {
          resolve(DATA);
        }, 200);
      });
    });
  }

  playAudioLoop(audio,action){
    console.error(action)
    loopify(audio.src,ready);
      function ready(err,loop){
        if (err) {
          console.warn(err);
        }      
        if(action=="play")
          loop.play();
          else if(action =="stop")
          loop.stop();
      }
  }

  capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  getDBName(){
    return environment.production?"NUBE":"ESPEJO";
  }
  quitarAcentos(palabra){
    palabra = palabra.replace(new RegExp(/[àáâãäå]/g),"a");
    palabra = palabra.replace(new RegExp(/[èéêë]/g),"e");
    palabra = palabra.replace(new RegExp(/[ìíîï]/g),"i");
    palabra = palabra.replace(new RegExp(/[òóôõö]/g),"o");
    palabra = palabra.replace(new RegExp(/[ùúûü]/g),"u");

    return palabra;
  }
  setCookieCategoriaDocente(txt){
    this.CookieCategoriaDocente = txt;
    localStorage.setItem("CookieCategoriaDocente", this.CookieCategoriaDocente);
    return this.CookieCategoriaDocente;
  }
  getCookieCategoriaDocente(){
    this.CookieCategoriaDocente = localStorage.getItem("CookieCategoriaDocente")==null?false:localStorage.getItem("CookieCategoriaDocente");
    return this.CookieCategoriaDocente;
  }
  setCookieMenuActual(txt){
    this.CookieMenuActual = txt;
    localStorage.setItem("CookieMenuActual", this.CookieMenuActual);
    return this.CookieMenuActual;
  }
  getCookieMenuActual(){
    this.CookieMenuActual = localStorage.getItem("CookieMenuActual")==null?false:localStorage.getItem("CookieMenuActual");
    return this.CookieMenuActual;
  }
  setCookieMenuActualLetrapps(txt){
    this.CookieMenuActualLetrapps = txt;
    localStorage.setItem("CookieMenuActualLetrapps", this.CookieMenuActualLetrapps);
    return this.CookieMenuActualLetrapps;
  }
  getCookieMenuActualLetrapps(){
    this.CookieMenuActualLetrapps = localStorage.getItem("CookieMenuActualLetrapps")==null?false:localStorage.getItem("CookieMenuActualLetrapps");
    return this.CookieMenuActualLetrapps;
  }
  go_to_landing(){
    this.router.navigate(['/']);
  }
  go_to_ruta(ruta){
    this.router.navigate(['/'+ruta]);
  }
  detenerAudioFrase(){

  }
  RoutingLetrapps:any = async (ruta: string, _showLoading_=false)=>{
    ruta = this.verificarPlataformaActual(ruta);
    
    console.error(ruta,this.PlataformaRutaActual);
    //console.error(this.router.routerState.snapshot.url);
    
    //this.verificarLogoCargando();

    this.EstoyEnLetraps = true;
    this.EstoyEnJuegoEduca = false;

    if(this.EstoyEnLetraps){
      $("#cargando_JE").addClass("hidden");
      $("#cargando_LT").addClass("hidden");
      $("#cargando_TBT").addClass("hidden");
      if(this.EstoyEnTabulatest){
        $("#cargando_TBT").removeClass("hidden");
        $("#cargando_LT").addClass("hidden");
      }else{
        $("#cargando_LT").removeClass("hidden");
      }
      
    }else{
      if(this.ActividadesEnLetrapps){
        $("#cargando_JE").addClass("hidden");
        $("#cargando_LT").addClass("hidden");
        $("#cargando_TBT").addClass("hidden");
        if(this.EstoyEnTabulatest){
          $("#cargando_TBT").removeClass("hidden");
          $("#cargando_LT").addClass("hidden");
        }else{
          $("#cargando_LT").removeClass("hidden");
        }
      }else{
        $("#cargando_LT").addClass("hidden");
        $("#cargando_TBT").addClass("hidden");
        $("#cargando_JE").removeClass("hidden");
      }
    }

    if(this.FuncionLimpiarJuego!==false){
      this.FuncionLimpiarJuego();
      this.FuncionLimpiarJuego = false;
    }

    if(_showLoading_){
      this.showLoading();
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
    if(_showLoading_){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate([ruta]));
      await new Promise(resolve => setTimeout(resolve, 1000));
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginTop:"1px", marginBottom:"-10px"});
      await new Promise(resolve => setTimeout(resolve, 500));
      this.hideLoading();
    }
    else{
      this.hideLoading();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate([ruta]));
    }
    
    //VALIDAR RUTA PARA ACCEDER A LOS JUEGOS sopa y popit DESDE LA LANDING A LA MISMA VERSION DE LETRAPPS
    this.verificarIntegracionJuegoEduca();
    this.verificarIntegracionLetrapps();
    
    return true;
    
  }
  
  entro=false;
  routing(a,b,c){
    console.log(a)
    this.NOMBRE_JUEGO_ACTUAL = c;
    if(true){
      if(a == "/https://www.tabulatest.cl/SilabarioMusicalDemo/EntrenamientoSimon"){
        window.location.href = 'https://www.tabulatest.cl/SilabarioMusicalDemo/EntrenamientoSimon';
      }else if(a == "https://letrapps.cl/GrafoTextoDemo"){
        window.location.href = 'https://letrapps.cl/GrafoTextoDemo';
      }else if(a == "https://letrapps.cl/KaraokeLectorDemo"){
        window.location.href = 'https://letrapps.cl/KaraokeLectorDemo';
      }else{
        if(!this.entro){
          this.entro = true;
          this.Routing(a,b)
          if(c=="maquina"){
            $("#bg-modal-cargando").removeClass("bg-cargando-normal");
            $("#bg-modal-cargando").addClass("bg-cargando-maquina");
          }else{
            $("#bg-modal-cargando").removeClass("bg-cargando-maquina");
            $("#bg-modal-cargando").addClass("bg-cargando-normal");
          }
        }
      }
    }else{
      this.alerta.fire({
        icon: 'warning',
        title: '¡Atención!',
        text: 'Falta muy poquito para habilitar este Juego.'
      })
    }
  }
  boton_actual:any = "";
  ruta_actual:any = "";
  _juego_actual_:any="";
  jugar(){
    $('#modal-listado').modal('hide');
    $('#modal-instruccion').modal('hide');
    this.routing(this._juego_actual_.url,true,this._juego_actual_.nombre);
    this.entro=false;
  }
  categoria_listado:any;
  ver_listado(url,array){
    $('.modal_listado_juegos').empty();
    $('.modal_listado_instruccion').empty();
    let actual = this.ArrayCategorias.filter(A=> A.src === url)[0];
    this.categoria_listado = actual;
    if(actual.tipo == "juegos_lsch"){
      array = "ArrayJuegosLsch";
    }
    let juegos = this[array].filter(A=> A.categoria.includes(actual.tipo));
    let html = "<ul>";
    for (let x = 0; x < juegos.length; x++) {
      const element = juegos[x];
      html += "<li>"+element.nombre_oficial+"</li>"
    }
    html += "</ul>";
    $('.modal_titulo').html('Juegos de '+'"'+actual.nombre+'"');
    $('.modal_listado_instruccion').html(actual.instruccion);
    $('.modal_listado_juegos').html(html);
    $('#modal-listado').modal();
  }
  actualizarSelectNivelesModoEntrenamiento(MODO_ENTRENAMIENTO,NIVELES_JUGADOS){
    if(MODO_ENTRENAMIENTO==="Si")MODO_ENTRENAMIENTO = true;
    if(MODO_ENTRENAMIENTO==="No")MODO_ENTRENAMIENTO = false;
    $('#dificultad').each(function(){
      $('#dificultad option').each(function() {
        if(!$(this).selected) {
          if(MODO_ENTRENAMIENTO){
            $(this).attr('disabled', false);
          }else{
            if($(this).val() != 1){
              if(NIVELES_JUGADOS<=($(this).val()-1)){
                $(this).attr('disabled', true);
              }
            }
           
          }
        }
      });
    });
  }
  getDatosColegio(id="5ad50cfb92efa2e6567d44d0",promocion=2022){
    return new Promise((resolve)=>{
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'API_KEY' : "2ba4961b-2fcb-452d-b4d5-01a8a76be66f",
        })
      };
      this.http.get<any>("https://api.gateway.integritic.cl/je/getDatosColegio/"+id,httpOptions).subscribe(response => {
        for(let x=0;x<response.inst[0].ALUMNOS.length;x++){
          response.inst[0].ALUMNOS[x].ALUMNOCURSO = response.inst[0].ALUMNOS[x].ALUMNOCURSO.filter(A=>A.PROMOCION === promocion)[0];
          response.inst[0].ALUMNOS[x].NOMBRE_RULETA = response.inst[0].ALUMNOS[x].NOMBRES.split(" ")[0]+" "+response.inst[0].ALUMNOS[x].APELLIDOPATERNO+" "+response.inst[0].ALUMNOS[x].APELLIDOMATERNO
        }
        resolve(response.inst[0]);
      }, response_err=>{
      });
    });
  }
  async showLoadingMg(time=20){

    $('#modalaso-mg').show();
    $('#modalaso-mg').addClass('jquery-loading-modal--visible').removeClass('jquery-loading-modal--hidden');
     /*let contador=0;
      let interval = setInterval(()=>{
         BarraProgreso.set(++contador);
         if(contador===100) clearInterval(interval);
      },time)*/
  }
  async hideLoadingMg(){
    
    $('#modalaso-mg').removeClass('jquery-loading-modal--visible').addClass('jquery-loading-modal--hidden');
    //await new Promise(resolve => setTimeout(resolve, 1000));
    $("#modalaso-mg").hide();
  }
}