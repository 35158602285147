import { Injectable } from '@angular/core';
import ObjectID from "bson-objectid"
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
declare var $: any;
declare var Swal: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  alerta = Swal;
  apiKey:any;
  apiUrl = "https://api.gateway.integritic.cl/je";
  apiUrl4 = "https://api.gateway.integritic.cl/sv4/juegoeduca/";
  dataServer:any={};
  listadoSilabas;
  isIframe:any=window.location !== window.parent.location;
  ID_INSTITUCION:any = "";
  PROMOCION:any = "";
  PLATAFORMA:any = "";
  ID_USUARIO:any = "";
  ID_RECURSO:any = "";
  OrigenIframe:any = "";
  TituloModal:any = "Seleccione";
  recursos = [
    {
      "id" : 304,
      "bloqueado" : false,
      "nuevo" : true,
      "nombre" : "MomiLetras",
      "url" : "Integracion_JuegoEduca_MomiLetras",
      "src" : "halloween",
      "variables" : true,
      "halloween" : true,
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "El tradicional juego del desarmado consiste en encontrar las letras escondidas que son parte de una palabra incógnita, antes que se acabe el tiempo."
    },
    {
      "id" : 303,
      "bloqueado" : false,
      "nuevo" : true,
      "nombre" : "Generador de Nube de Palabras",
      "url" : "extras/nube-palabras",
      "src" : "nube",
      "variables" : true,
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Este recurso te permitirá crear gráficos de Nube de Palabras con diversas configuraciones."
    },
    {
      "id" : 302,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Generador de Historias",
      "url" : "extras/generador-historias",
      "src" : "historias",
      "variables" : true,
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso para crear historias a partir de frases aleatorias."
    },
    {
      "id" : 301,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Vocabulario",
      "url" : "Vocabulario",
      "src" : "vocabulario",
      "variables" : true,
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para filtrar las palabras más utilizadas de los textos escolares."
    },
    
    {
      "id" : 300,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Sopa de Sílabas y Letras",
      "url" : "extras/sopa-de-silabas",
      "src" : "sopa",
      "variables" : true,
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso para generar Sopa de Sílabas y Letras de palabras y textos."
    },
    {
      "id" : 5,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Temporizador",
      "url" : "extras/temporizador",
      "src" : "temporizadores",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : true,
      "instruccion" : "Recursos para gestionar el uso del tiempo en tu sala de clases.",
      "datos_modal" : [
        {
          "id" : 101,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "Numérico",
          "url" : "extras/temporizador",
          "src" : "cronometro",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
          "externo" : false,
          "instruccion" : "Timer numérico para gestionar el tiempo.",
          "clase" : 3,
        },
        {
          "id" : 102,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "Visuales Geométricos",
          "url" : "extras/temporizadorvisual",
          "src" : "visuales",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
          "externo" : false,
          "instruccion" : "Timer que proporciona una representación visual del tiempo.",
          "clase" : 3,
        },
        {
          "id" : 103,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "Visuales Emojis",
          "url" : "extras/temporizadoremojis",
          "src" : "temporizador3",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
          "externo" : false,
          "instruccion" : "Timer que proporciona una representación visual del tiempo utilizando Emojis.",
          "clase" : 3,
        },
        {
          "id" : 104,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "Sensorial",
          "url" : "extras/temporizadorsensorial",
          "src" : "sensorial",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
          "externo" : false,
          "instruccion" : "Timer que proporciona una forma de representar el tiempo de forma relajante.",
          "clase" : 3,
        },
      ]
    },
    {
      "id" : 1,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Ruidómetro",
      "url" : "extras/ruidometro",
      "src" : "ruidometro",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso que permite controlar el ruido en la sala de clases de manera entretenida."
    },
    {
      "id" : 2,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Respiración Guiada",
      "url" : "extras/respirometro",
      "src" : "respirometro",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso que permite trabajar la respiración para calmar la sala de clases."
    },
    // {
      // "id" : 3,
      //"bloqueado" : true,
    //   "nombre" : "Emocionómetro",
    //   "url" : "extras/ruidometro",
    //   "src" : "emocionometro",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    {
      "id" : 4,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Abecedario interactivo",
      "url" : "abecedario_interactivo",
      "src" : "abecedario",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso para conocer y trabajar con las letras del abecedario de forma interactiva y que además incluye el alfabeto en lengua de señas y braille."
    },
    // {
      // "id" : 5,
      //"bloqueado" : true,
    //   "nombre" : "Pomodoro",
    //   "url" : "extras/ruidometro",
    //   "src" : "pomodoro",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    {
      "id" : 6,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Cumpleaños",
      "url" : "extras/saludo-cumpleanos/",
      "variables" : true,
      "src" : "feliz",
      "plataforma" : ["letrapps","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso que permite conocer las fechas de cumpleaños de todos los estudiantes del establecimiento separados por curso y letra."
    },
    {
      "id" : 7,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Selector de estudiantes",
      "url" : "extras/selector-estudiantes",
      "variables" : true,
      "src" : "selector",
      "plataforma" : ["NOIFRAME","letrapps","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso que permite realizar selección al azar de estudiantes para trabajar en la sala de clases"
    },
    {
      "id" : 20,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Método Global",
      "url" : "MetodoGlobal",
      "src" : "metodo",
      "variables" : true,
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso de apoyo para trabajar el método global de lectura palabras más palabras."
    },
    {
      "id" : 25,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Pictogramas",
      "url" : "https://test.pictolandia.com/",
      "src" : "pictos",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : true,
      "instruccion" : "Recursos para el trabajo con pictogramas.",
      "datos_modal" : [
        {
          "id" : 101,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "PictoSecuencias",
          "url" : "Pictogramas/PictoSecuencias",
          "src" : "secuencias",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
          "externo" : true,
          "instruccion" : "Convierte palabras y frases en secuencias pictográficas."
        },
        {
          "id" : 252,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "PictoLecturas",
          "url" : "Pictogramas/PictoLecturas",
          "src" : "pictolecturas",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
          "externo" : true,
          "instruccion" : "Convierte lecturas a pictogramas"
        },
        {
          "id" : 253,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "PictoMateriales",
          "url" : "Pictogramas/PictoRecursos",
          "src" : "materiales",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
          "externo" : true,
          "instruccion" : "Diversos recursos descargables."
        }
      ]
    },
    {
      "id" : 26,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Braille",
      "url" : "traductor_braille",
      "variables" : true,
      "src" : "braille",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso que permite realizar la traducción en tiempo real desde un texto tradicional al alfabeto braille."
    },
    // {
      // "id" : 8,
      //"bloqueado" : true,
    //   "nombre" : "Diccionario",
    //   "url" : "extras/ruidometro",
    //   "src" : "diccionario",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    // {
      // "id" : 9,
      //"bloqueado" : true,
    //   "nombre" : "Cronómetro",
    //   "url" : "extras/ruidometro",
    //   "src" : "cronometro",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    // {
      // "id" : 10,
      //"bloqueado" : true,
    //   "nombre" : "Generador QR",
    //   "url" : "extras/ruidometro",
    //   "src" : "qr",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    // {
      // "id" : 11,
      //"bloqueado" : true,
    //   "nombre" : "Semáforo",
    //   "url" : "extras/ruidometro",
    //   "src" : "semaforo",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    // {
      // "id" : 12,
      //"bloqueado" : true,
    //   "nombre" : "Traductor de Audio a Texto",
    //   "url" : "extras/ruidometro",
    //   "src" : "textovoz",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    // {
      // "id" : 13,
      //"bloqueado" : true,
    //   "nombre" : "Reloj de arena",
    //   "url" : "extras/ruidometro",
    //   "src" : "reloj",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    {
      "id" : 14,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Deslizógrafo",
      "url" : "Deslizografo",
      "src" : "deslizografo",
      "plataforma" : ["letrapps","tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso que permite trabajar palabras, sílabas y fonemas. especialmente diseñado para el método matte."
    },
    {
      "id" : 15,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Karaoke Lector",
      "url" : "KaraokeLector",
      "src" : "karaoke",
      "plataforma" : ["letrapps","tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para trabajar lecturas de forma interactiva , con distintas velocidades, formatos y  juegos."
    },
    {
      "id" : 16,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Juegos de Lectura",
      "url" : "seleccion-juegos",
      "src" : "juegos",
      "plataforma" : ["letrapps","localhost"],
      "externo" : false,
      "modal" : true,
      "instruccion" : "Recurso basado en variados juegos de lectoescritura.",
      "datos_modal" : [
        {
          "id" : 161,
          "bloqueado" : false,
          "nuevo" : false,
          "clase" : 4,
          "nombre" : "Juegos Lectores",
          "url" : "seleccion-juegos",
          "src" : "lectores",
          "plataforma" : ["NOIFRAME","letrapps","juegoeduca","inclutics","localhost"],
          "externo" : false,
          "instruccion" : "Acceso a Juegos Lectores “JuegoEduca”."
        },
        {
          "id" : 162,
          "bloqueado" : false,
          "nuevo" : false,
          "clase" : 4,
          "nombre" : "Letrapps Gamer",
          "url" : "Juegos",
          "src" : "letrappsg",
          "plataforma" : ["letrapps","juegoeduca","inclutics","localhost"],
          "externo" : true,
          "instruccion" : "Acceso a Letrapps Gamer."
        },
        {
          "id" : 163,
          "bloqueado" : false,
          "nuevo" : false,
          "clase" : 4,
          "nombre" : "Monitoreo Letrapps Gamer",
          "url" : "Monitoreo",
          "src" : "monitoreo",
          "plataforma" : ["letrapps","inclutics","localhost"],
          "externo" : true,
          "instruccion" : "Acceso monitoreo de juegos Letrapps Gamer."
        },
      ]
    },
    {
      "id" : 17,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Juegos de Lectura",
      "url" : "seleccion-juegos",
      "src" : "juegos",
      "plataforma" : ["NOIFRAME","tabulatest","localhost"],
      "externo" : false,
      "modal" : true,
      "instruccion" : "Recurso basado en variados juegos de lectoescritura.",
      "datos_modal" : [
        {
          "id" : 171,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "Juegos Lectores",
          "url" : "seleccion-juegos",
          "src" : "lectores",
          "plataforma" : ["NOIFRAME","letrapps","tabulatest","inclutics","localhost"],
          "externo" : false,
          "instruccion" : "Acceso a Juegos Lectores “JuegoEduca”.",
          "clase" : 6,
        },
        {
          "id" : 172,
          "bloqueado" : false,
          "nuevo" : false,
          "nombre" : "Letrapps Gamer",
          "url" : "Juegos",
          "src" : "letrappsg",
          "plataforma" : ["NOIFRAME","letrapps","tabulatest","inclutics","localhost"],
          "externo" : true,
          "instruccion" : "Acceso a Letrapps Gamer.",
          "clase" : 6,
        }
      ]
    },
    {
      "id" : 35,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Juegos de Matemáticas",
      "url" : "Categoria_de_Juegos",
      "src" : "matematicas",
      "plataforma" : ["tabulatest","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso basado en variados juegos de matemáticas.",
    },
    {
      "id" : 18,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Diplomas",
      "url" : "https://diploma.letrapps.cl/",
      "src" : "diploma",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para confeccionar diplomas automáticos para los estudiantes."
    },
    {
      "id" : 19,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Diplomas",
      "url" : "https://diploma.tabulatest.cl/",
      "src" : "diploma",
      "plataforma" : ["NOIFRAME","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para confeccionar diplomas automáticos para los estudiantes."
    },
   
    // {
      // "id" : 20,
      //"bloqueado" : true,
    //   "nombre" : "Generador pseudopalabras",
    //   "url" : "extras/ruidometro",
    //   "src" : "abecedario",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    // {
      // "id" : 21,
      //"bloqueado" : true,
    //   "nombre" : "Generador sopa de letras",
    //   "url" : "extras/ruidometro",
    //   "src" : "abecedario",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    //"modal" : false
    // },
    {
      "id" : 22,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "GrafoTextos",
      "url" : "https://letrapps.cl/GrafoTextoDemo",
      "src" : "grafo",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso que permite generar ejercicios de grafomotricidad."
    },
    {
      "id" : 23,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Silabario Musical",
      "url" : "SimonLee",
      "src" : "silabario",
      "plataforma" : ["letrapps","tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso interactivo para trabajar las sílabas con música y colores."
    },
    {
      "id" : 24,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Lengua de Señas",
      "url" : "LSCH/palabras_lsch",
      "src" : "lengua",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para el aprendizaje y práctica de la lengua de señas chilena."
    },
    
   
    {
      "id" : 27,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Priorización Curricular",
      "url" : "ConsultaObjetivos",
      "src" : "priorizacion",
      "plataforma" : ["letrapps","tabulatest","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para buscar y descargar OAS de la priorización curricular."
    },
    {
      "id" : 28,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Envío de Tareas",
      "url" : "Tareas",
      "src" : "tareas",
      "plataforma" : ["tabulatest","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para enviar documentos y tareas a los estudiantes."
    },
    {
      "id" : 29,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Calculadora V. Lectora",
      "url" : "CalculadoraPPM",
      "src" : "calculadora",
      "plataforma" : ["letrapps","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para aprender a calcular la velocidad lectora."
    },
    {
      "id" : 30,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Actividades Imprimibles",
      "url" : "actividades-para-imprimir/RECURSOS?ocultar_boton=true&plataforma=TABULATEST",
      "src" : "impresion",
      "plataforma" : ["NOIFRAME","juegoeduca","tabulatest","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso que permite realizar actividades personalizables para imprimir."
    },
    {
      "id" : 31,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Actividades Imprimibles",
      "url" : "actividades-para-imprimir/RECURSOS?ocultar_boton=true&plataforma=LT",
      "src" : "impresion",
      "plataforma" : ["NOIFRAME","letrapps","juegoeduca","inclutics","localhost"],
      "externo" : false,
      "modal" : false,
      "instruccion" : "Recurso que permite realizar actividades personalizables para imprimir."
    },
    {
      "id" : 32,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Reservar hora",
      "url" : "Calendario/Configuracion",
      "src" : "reservar",
      "plataforma" : ["tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para gestionar y organizar citaciones/horas."
    },
    {
      "id" : 33,
      "bloqueado" : false,
      "nuevo" : false,
      "nombre" : "Ver calendario",
      "url" : "Calendario/Ver",
      "src" : "ver",
      "plataforma" : ["tabulatest","inclutics","localhost"],
      "externo" : true,
      "modal" : false,
      "instruccion" : "Recurso para gestionar y organizar citaciones/horas."
    },
    // {
      // "id" : 27,
    //   "nombre" : "Dados mágicos",
    //   "url" : "extras/ruidometro",
    //   "src" : "dados",
    //   "plataforma" : ["NOIFRAME","letrapps","juegoeduca","tabulatest","inclutics","localhost"],
    //   "externo" : false,
    // "modal" : false
    // }

  ]
  base_url:any = "";
  url_api:any;

  // apiUrl = "http://localhost:3000";
  constructor(private http: HttpClient,private router: Router){ 
    this.base_url = window.location.origin;
    if(this.base_url == "https://juegoeduca.com" || this.base_url == "https://www.juegoeduca.com" || this.base_url == "https://test.juegoeduca.com" || this.base_url == "https://www.test.juegoeduca.com"){
      this.base_url = "https://letrapps.cl";
    }else{
      this.base_url = "https://letrapps.cl";
    }
    let url = document.referrer;
    if(url.includes('tabulatest')){
      this.OrigenIframe = "tabulatest";
    }else if(url.includes('inclutics')){
      this.OrigenIframe = "inclutics";
    }else if(url.includes('letrapps')){
      this.OrigenIframe = "letrapps";
    }else if(url.includes('juegoeduca')){
      this.OrigenIframe = "juegoeduca";
    }else if(url == ""){
      this.OrigenIframe = "NOIFRAME";
    }else{
      this.OrigenIframe = "localhost";
    }
    if(environment.production){
      this.apiKey = "3ac0ebae-b057-40b5-86e3-2f782e8b71d3";
    }
    else{
      if(location.hostname === "localhost" || location.hostname === "locajost.com"){
        this.apiKey = "1fececde-581b-4c61-9c32-5ac3b4024609";
        this.apiUrl = "http://localhost:3333";
      }
      else {
        this.apiKey = "2ba4961b-2fcb-452d-b4d5-01a8a76be66f";
      }
    }
    this.ID_USUARIO = localStorage.getItem("ID_USUARIO");
    this.PLATAFORMA = localStorage.getItem("PLATAFORMA");
    this.ID_INSTITUCION = localStorage.getItem("ID_INSTITUCION");
    this.PROMOCION = localStorage.getItem("PROMOCION");
    // this.apiUrl = "https://api.gateway.integritic.cl/je";
    this.url_api = window.location.origin.includes('localhost') ? "http://127.0.0.1:3445/" : "https://api.gateway.integritic.cl/sv5/";
    // this.url_api = "https://api.gateway.integritic.cl/sv5/";
    // this.apiKey = "2ba4961b-2fcb-452d-b4d5-01a8a76be66f";
  }
  getCategoriasPalabras(){
    return new Promise(resolve=>{
      this.http.post<any>(this.url_api+'juegoeduca/get/categorias_semanticas',JSON.stringify({api_key : this.apiKey})).subscribe(response => {
        response = response.filter(A=> A.INGLES_CATEGORIA_PALABRAS != "true");
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getDatosUsuario(params2){
    return new Promise(resolve=>{
      this.http.post<any>(this.url_api+'juegoeduca/DatosUsuario/',JSON.stringify({ID_USUARIO: params2.usuario, ID_INSTITUCION: params2.institucion, api_key : this.apiKey, plataforma: params2.plataforma,})).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getAlumnosCurso(datos){
    return new Promise(resolve=>{
      this.http.post<any>(this.url_api+'juegoeduca/post/estudiantes_curso/',JSON.stringify({curso: datos.curso, letra: datos.letra, promocion: datos.promocion, api_key : this.apiKey, institucion: datos.institucion, plataforma: datos.plataforma,})).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getCursosSam(datos){
    return new Promise(resolve=>{
      this.http.post<any>(this.url_api+'juegoeduca/post/listado_curso/',JSON.stringify({api_key : this.apiKey, institucion: datos.institucion, plataforma: datos.plataforma})).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getFrases(){
    return new Promise(resolve=>{
      this.http.post<any>(this.url_api+'juegoeduca/get/frases_generador',JSON.stringify({api_key : this.apiKey})).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getSilabas(SILABAS){
    return new Promise(resolve=>{
      this.http.post<any>(this.apiUrl+"/getAudiosSilabas", {
        SILABAS: SILABAS,
      }, this.httpOptions()).subscribe(response => {
        this.listadoSilabas = response;
        resolve(response);
        return response;
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getImgb64(url){
    return new Promise(resolve=>{
      this.http.post<any>(this.apiUrl+"/url_to_b64", {
        URL: url,
      }, this.httpOptions()).subscribe(response => {
        resolve(response)
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getAbecedario():any{
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/getAbecedario",this.httpOptions(false)).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getTextos():any{
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/getTextos",this.httpOptions(false)).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  analizeComprehend(texto):any{
    return new Promise(resolve=>{
      this.http.post<any>(this.apiUrl4+"procesar/texto/", JSON.stringify({
        texto: texto,
      })).subscribe(response => {
        resolve(response)
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getPalabrasMetodoGlobal(etapa):any{
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/getPalabrasMetodoGlobal/"+etapa,this.httpOptions(false)).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getPalabrasCategoria(categoria):any{
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/getPalabrasCategoria/"+categoria,this.httpOptions(false)).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  getCantidadPalabras():any{
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/getCantidadPalabras",this.httpOptions(false)).subscribe(response => {
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  readToken(){
    return localStorage.getItem("token");
  }
  httpOptions(token:any = false){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api_key' : this.apiKey,
        'token' : token===true?this.readToken():token
      })
    };
    if(token) return httpOptions;
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api_key' : this.apiKey,
      })
    };
  }
  httpOptionsSam(){
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-key' : this.apiKey,
      })
    };
  }
  verifyToken(token){
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/verify/token",this.httpOptions(token)).subscribe(response => {
        this.saveToken(response.token);
        resolve(response); 
      }, response_err=>{
        this.saveToken(false);
        resolve(response_err.error)
      });
    });
  }
  saveToken(token){
    localStorage.setItem("token", token);
  }
  generateToken(){
    this.http.get<any>(this.apiUrl+"/create/token",this.httpOptions()).subscribe(response => {
    }, response_err=>{
      console.log(response_err.error)
    });
  }
  getToken(){
    let token = localStorage.getItem("token");
    return new Promise(resolve=>this.verifyToken(token).then(response=>resolve(response)));
  }
  getFrasesPalabras(palabras){
    return new Promise(resolve=>{
      this.http.post<any>(this.apiUrl+"/getFrasesPalabras", {
        PALABRAS: palabras.map(pa=>pa._id),
      }, this.httpOptions()).subscribe(response => {
        resolve(response)
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }




  /** selector estudiantes */
  
  // /get/estudiantes/curso/juegoeduca/{id}/{letra}/{promocion}
  getEstudiantesInstitucion(params){
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/get/estudiantes/curso/juegoeduca/"+params.curso+"/"+params.letra+"/"+params.promocion+"__"+params.plataforma, this.httpOptions()).subscribe(response => {
        resolve(response)
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  valoracion(id){
    $('.emoticones').removeClass('valoracion');
    $('#'+id).addClass('valoracion');
  }
  guardando:any = false;
  async setDatosValoracion(){
    if(!this.guardando){
      let datos = {
        tipo: $('.valoracion').eq(0).attr('id'),
        texto: $('#valor_texto').val(),
        recurso: $('.modulo_valoracion').html()
      }
      if($('.valoracion').eq(0).attr('id')){
          if($('.valoracion').eq(0).attr('id') == 'bajo' || $('.valoracion').eq(0).attr('id') == 'medio'){
            if($('#valor_texto').val().length == 0){
                Swal.fire({
                    title: "",
                    text: "¡Por favor danos un comentario para saber como mejorar!",
                    imageUrl: "https://soporte-integritic.s3.amazonaws.com/triste.svg",
                    imageWidth: 200,
                    showDenyButton: false,
                    showCancelButton: false,
                    cancelButtonText: "Ok",
                })
                $('.swal2-modal').css('transform','scale(1.5)');
            }else{
              await this.setValoracion(datos);
            }
          }else{
            await this.setValoracion(datos);
          }
      }else{
          Swal.fire({
              title: "¡Upss...!",
              text: "Le faltó seleccionar una opción de evaluación",
              icon: "info",
              showDenyButton: false,
              showCancelButton: false,
              cancelButtonText: "Ok",
          })
      }
    } 
  }
  abrir_valoracion(nombre){
    this.ID_RECURSO = nombre;
    $('.emoticones').removeClass('valoracion');
    $('#valor_texto').val("");
    $('#modal-valoracion').modal();
  }
  getCursosInstitucion(params){
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/get/cursos/institucion/juegoeduca/"+params.institucion+"/"+params.promocion+"__"+params.plataforma, this.httpOptions()).subscribe(response => {
        resolve(response)
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }

  

  getDatosZonaHoraria(){
    return new Promise(resolve=>{
      this.http.get<any>("https://worldtimeapi.org/api/timezone/America/Santiago", this.httpOptions()).subscribe(response => {
        resolve(response)
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  
  getEstudiantesCumpleaneros(params){
    return new Promise(resolve=>{
      this.http.get<any>(this.apiUrl+"/get/estudiantes/cumpleanos/juegoeduca/"+params.institucion+"/"+params.promocion+"__"+params.plataforma, this.httpOptions()).subscribe(response => {
        resolve(response)
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  cerrar_modal(){
    $('#gameContainer').removeClass('hidden');
  }
  setValoracion(datos){
    this.guardando = true;
    return new Promise((resolve)=>{
      this.http.post(this.apiUrl+"/setValoracion", JSON.stringify(datos), this.httpOptions()).subscribe(response => {
          Swal.fire({
            title: "¡Hemos recibido tu opinión!",
            text: "Tus comentarios nos ayudarán a mejorar",
            imageUrl: "https://soporte-integritic.s3.amazonaws.com/gracias.svg",
            imageWidth: 200,
            showDenyButton: false,
            showCancelButton: false,
            cancelButtonText: "Ok",
          });
          this.guardando = false;
          $('#gameContainer').removeClass('hidden');
          $('#modal-valoracion').modal('hide');
          resolve(response);
        });
    })
  }
  async setKpiJuegoEduca(MODULO,ACCION,DETALLE){
    if(this.isIframe){
      return false;
    };
    let IP_ = await this.getIpUsuario();
    let datos = {
      MODULO : MODULO,
      ACCION: ACCION,
      DETALLE: JSON.stringify(DETALLE),
      RUTA: this.router.url,
      IP: IP_,
      api_key : this.apiKey
    }
    // return new Promise(resolve=>{
    //   this.http.post<any>(this.url_api+'juegoeduca/setKpiJuegoEduca/',JSON.stringify(datos)).subscribe(response => {
    //     console.log(response)
    //     resolve(response);
    //   }, response_err=>{
    //     resolve(response_err.error)
    //   });
    // });
    return new Promise((resolve)=>{
      this.http.post(this.apiUrl+"/setKpiJuegoEduca", JSON.stringify(datos), this.httpOptions()).subscribe(response => {
          resolve(response);
        });
    })
  }
  getIpUsuario(){
    return new Promise(resolve=>{
      this.http.get<any>(this.base_url+'/api/GET/GET_IP_USUARIO').subscribe(DATA => {
        resolve(DATA);
      });
    });
  }
  enviar_sugerencia(nombre,url){
    window.parent.postMessage({ type: 'sugerencia', url: url, nombre: nombre}, '*');
  }
  set_kpi_plataformas(modulo,texto){
    this.setKpiJuegoEduca(modulo,texto,{});
    window.parent.postMessage({ type: 'kpi',nombre: modulo, accion: texto}, '*');
  }
  valoraciones(nombre,url){
    $('.modulo_valoracion').html(nombre);
    $('#modal-valoracion').modal({
      backdrop : "static",
      keyboard: false
    });
    $('#gameContainer').addClass('hidden');
  }
}


